
/**
 * add TrackingInfo for product
 */
export function addTrackingInfoProduct(response: any) {
    const platformData = (window as any).platformData;

    if (platformData && typeof platformData !== "undefined") {
        const city = $(response).data("city");
        const price = $(response).data("price");
        const date = $(response).data("date");
        const returndate = $(response).data("returndate");
        const advanced = $(response).data("advanced");
        const mealplan = $(response).data("mealplan");
        const days = $(response).data("days");
        const nights = $(response).data("nights");
        const weekday = $(response).data("weekday");
        platformData.push({
            event: "visit",
            product: {
                advancedBooking: advanced,
                trip: {
                    duration: days,
                    outboundOriginName: city,
                    outboundDepartureWeekDay: weekday
                },
                priceUserCurrency: price,
                accomodation: {
                    startDate: date,
                    endDate: returndate,
                    mealPlan: mealplan,
                    duration: nights
                }
            }
        });
    }
}
