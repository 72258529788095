import * as $$ from "jquery-selector-cache";

/**
 * Initialize the module
 */
export function initUnbeatablePopIn() {
    const popIn = $(".unbeatableOffer-container");
    const displayPopIn = JSON.parse(localStorage.getItem("display-pop-in"));

    if (displayPopIn === null || displayPopIn != new Date().getDate()) {
        popIn.removeClass("d-none");
    }

    $$("#close-pop-in").on("click.toggleLink", () => {
        popIn.addClass("d-none");
        window.localStorage.setItem("display-pop-in", `${new Date().getDate()}`);
    });
}