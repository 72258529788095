/**
 * Default URL parameters constants
 * @type {string<object>}
 */
export const urlParametersConfig = {
    day: "s_dd",
    monthYear: "s_dmy",
    departureDate: "s_dpda",
    departureCity: "s_dpci",
    destination: "s_c.de",
    to: "s_to",
    and: "++",
    or: ",",
    trackingId: "trackingId",
    useAdv: "useAdv",
    adults: "adults",
    children: "children",
    birthdates: "birthdate",
    searchSubmit: "searchSubmit",
    canal: "canal"
};

/**
 * Default URL refine parameters constants
 * @type {string<object>}
 */
export const urlRefineParametersConfig = {
    day: "ref_dd",
    monthYear: "ref_dmy",
    departureDate: "ref_dpda",
    destination: "ref_c.de",
    departureCity: "ref_dpci",
    durationDay: "ref_mmd",
    durationNight: "ref_minMan"
};

/**
 * Default Pikaday configuration
 * @type {string<object>}
 */
export const pikadayConfig = {
    "i18n": {
        "previousMonth": ">",
        "nextMonth": ">",
        "months": ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        "weekdays": ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
        "weekdaysShort": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]
    }
};
