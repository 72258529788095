import * as React from "react";
import {ReactElement} from "react";

export interface IconProps {
    icon: string;
    addClass?: string;
}

/**
 * Icon
 * @param icon
 * @param addClass
 * @constructor
 */
const Icon = ({icon, addClass = ""}: IconProps): ReactElement => {
    return (
        <i className={`elem-ff-icon ${addClass}`} data-icon={icon} role="img"/>
    );
};

export default Icon;
