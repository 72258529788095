import * as React from "react";
import CheckFilterItem from "./CheckFilterItem";
import RangeFilterItem from "./RangeFilterItem";
import {getSearchType, ISearchType} from "orxapi.widgets.framework/dist/tools/formTools";

/**
 * type of search item
 */
export enum FilterItemType {
    RANGE = "range",
    CHECK = "check",
    SELECT = "select"
}

/**
 * return the right component by type
 * @param item the item to render
 * @param searchTypes search type by item
 * @param onChange when item status change
 */
export function getFilterItemComponent(item: IFilterItem, searchTypes: ISearchType[], onChange?: (evt: any) => void): IFilterItemComponent {
    const type = item.type;
    const data = item.data;
    const searchType = getSearchType(data.name, searchTypes);
    if (type === FilterItemType.CHECK) {
        if (data.options && data.options.length > 0) {
            return <CheckFilterItem {...data} searchType={searchType} onChange={onChange}/>;
        } else {
            return null;
        }
    } else if (type === FilterItemType.RANGE) {
        return <RangeFilterItem {...data} onChange={onChange}/>;
    } else {
        return null;
    }
}

/**
 * interface described a search item
 */
export interface IFilterItem {
    label: string;
    type: FilterItemType;
    data: any;
}

/**
 * interface described a search item
 */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFilterItemComponent {
}
