import * as ReactDOM from "react-dom";
import * as React from "react";
import {Provider} from "mobx-react";
import {ISearchType, urlParameters} from "orxapi.widgets.framework/dist/tools/formTools";
import {IUrlParam} from "orxapi.tools.url/dist/IUrl";
import SearchEngineLastminuteLayout, {ISearchEngine} from "./SearchEngineLastminuteLayout";
import {getUrlVar} from "orxapi.tools.url/dist/index";
import {SearchEngineStore} from "./SearchEngineStore";
import {urlParametersConfig} from "../config/form";

/**
 * init react search engine inside root element
 * @param root
 * @param dataUrl url to retrieve the json
 * @param callback
 */
export function initSearchEngine(root: HTMLElement, dataUrl: string, callback?: () => void) {
    $.ajax({
        url: dataUrl,
        dataType: "json"
    }).done((response: ISearchEngine[]) => {
        const $root = $(root);
        const searchEngineStore = new SearchEngineStore(response);
        for (const searchEngine of response) {
            const defaultValues = searchEngine.defaultValues;
            if (defaultValues && defaultValues.length > 0) {
                for (const defaultValue of defaultValues) {
                    if (defaultValue.code && defaultValue.code !== "" && defaultValue.value) {
                        searchEngineStore.selectValue(defaultValue.code, defaultValue.value.split(urlParameters.or));
                    }
                }
            }
        }
        const parameters = getUrlVar();
        initStoreFromParameters(searchEngineStore, parameters);

        ReactDOM.unmountComponentAtNode(root); // In case of the search engine was already initialized
        ReactDOM.render((
            <Provider searchEngineStore={searchEngineStore}>
                {getSearchEngineLayout($root)}
            </Provider>
        ),
        root,
        callback
        );
    });
}

/**
 * Initializes the search engine store from given url parameters
 * @param searchEngineStore search engine store
 * @param parameters url parameters
 */
function initStoreFromParameters(searchEngineStore: SearchEngineStore, parameters: IUrlParam) {
    let searchTypes: ISearchType[];
    if (searchEngineStore && searchEngineStore.currentSearchEngine && searchEngineStore.currentSearchEngine.searchTypes as ISearchType[]) {
        searchTypes = searchEngineStore.currentSearchEngine.searchTypes;
    }
    const pathname = window.location.pathname;
    const countryRegex = /^\/([A-z0-9]+)-([A-z0-9-]*)$/;
    const cityRegex = /^\/([A-z0-9]+)-([A-z0-9-]*)\/([A-z0-9-]*)$/;
    if (pathname.match(cityRegex)) {
        const city = pathname.replace(cityRegex, "$2.$3");
        parameters[urlParametersConfig.destination] = city;
    } else if (pathname.match(countryRegex)) {
        const country = pathname.replace(countryRegex, "$2");
        parameters[urlParametersConfig.destination] = country;
    }
    for (const parametersKey of Object.keys(parameters)) {
        let searchType = urlParameters.or;
        if (searchTypes && searchTypes.length > 0) {
            for (const currentSearchType of searchTypes) {
                if (currentSearchType.code  && currentSearchType.code === parametersKey && currentSearchType.value  && currentSearchType.value === "and") {
                    searchType = urlParameters.and;
                }
            }
        }
        searchEngineStore.selectValue(parametersKey, parameters[parametersKey].split(searchType));
    }
}

/**
 * get the correct layout from root data
 * @param root the root where the layout will be place
 */
function getSearchEngineLayout(root: JQuery): JSX.Element {
    return (
        <SearchEngineLastminuteLayout
            {...root.data()}
        />
    );
}
