import * as ReactDOM from "react-dom";
import * as React from "react";
import FilterEngineLayout, {IFilterEngine} from "./FilterEngineLayout";
import {CheckOptionFilterStore} from "./FilterItem/CheckFilterItem/CheckOptionFilterStore";
import {Provider} from "mobx-react";
import FilterStore from "./FilterStore";

/**
 * init react search engine inside root element
 * @param root
 * @param dataUrl url to retrieve the json
 * @param callback
 */
export function initFilterEngine(root: HTMLElement, dataUrl: string, callback?: () => void) {
    $.ajax({
        url: dataUrl,
        dataType: "json"
    }).done((response: IFilterEngine) => {
        const filterStore = new FilterStore(new CheckOptionFilterStore());
        ReactDOM.render((
            <Provider filterStore={filterStore}>
                <FilterEngineLayout {...$(root).data()} filterEngine={response}/>
            </Provider>
        ), root, callback
        );
    });
}
