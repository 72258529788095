/**
 * the store for the check filter
 */
import {action, observable} from "mobx";

/**
 * interface represent a value can be checked
 */
export interface IValueCheckable {
    value: string;
    check: boolean;
    disabled?: boolean;
}

/**
 * the check option store
 */
export class CheckOptionFilterStore {
    private readonly options: { [name: string]: IValueCheckable[] };

    /**
     * the constructor to initialize store
     */
    constructor() {
        this.options = {};
    }

    /**
     * add list of option for a given name
     * @param name
     * @param options
     */
    public addOptions(name: string, options: IValueCheckable[]) {
        if (this.options[name]) {
            this.options[name].push(...options.filter((o: IValueCheckable) => this.options[name].indexOf(o) === -1));
        } else {
            this.options[name] = observable(options);
        }
    }

    /**
     * change the check value of an option
     * @param name
     * @param value
     */
    @action.bound
    public toggleCheck(name: string, value: string) {
        this.options[name]
            .filter((option: IValueCheckable) => option.value === value)
            .forEach((option: IValueCheckable) => option.check = !option.check);
    }

    /**
     * change the check value of an option
     * @param name
     * @param value
     * @param check
     */
    @action.bound
    public setCheck(name: string, value: string, check: boolean) {
        this.options[name]
            .filter((option: IValueCheckable) => option.value === value)
            .forEach((option: IValueCheckable) => option.check = check);
    }

    /**
     * reinit all checkbox for this name
     * @param name
     */
    @action.bound
    public reinit(name: string) {
        return this.options[name]
            .forEach((option: IValueCheckable) => option.check = false);
    }

    /**
     * reinit all checkbox
     */
    @action.bound
    public resetAll() {
        return Object.keys(this.options)
            .forEach((key: string) => this.options[key]
                .forEach((option: IValueCheckable) => option.check = false));
    }

    /**
     * return check value of an option
     * @param name
     * @param value
     */
    public isCheck(name: string, value: string): boolean {
        return this.options[name]
            .filter((option: IValueCheckable) => option.value === value)
            .some((option: IValueCheckable) => option.check);

    }

    /**
     * return check value of an option
     * @param name
     * @param value
     */
    public setDisabled(name: string, value: string, disabled: boolean) {
        return this.options[name]
            .filter((option: IValueCheckable) => option.value === value)
            .some((option: IValueCheckable) => option.disabled = disabled);

    }

    /**
     * return check value of an option
     * @param name
     * @param isCheck
     */
    @action.bound
    public handleAllCheck(name: string, isCheck: boolean) {
        return this.options[name]
            .filter((option: IValueCheckable) => !option.disabled)
            .forEach((option: IValueCheckable) => option.check = isCheck);

    }

    /**
     * isAllCheck
     * @param name
     */
    public isAllCheck(name: string): boolean {
        if (this.options[name]) {
            return this.options[name]
                .filter((option: IValueCheckable) => (option.disabled === false && option.check === false)).length === 0;
        } else {
            return true;
        }
    }

    /**
     * reset all values of an option
     * @param name
     */
    public reset(name: string) {
        return this.options[name]
            .forEach((option: IValueCheckable) => option.check = false);

    }
}
