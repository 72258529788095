import * as React from "react";
import {ChangeEvent, ChangeEventHandler, Component} from "react";
import {inject, observer} from "mobx-react";
import {getUrlVar} from "orxapi.tools.url";
import {IValueCheckable} from "./CheckOptionFilterStore";
import FilterStore from "../../FilterStore";
import {IFilterItemComponent} from "../index";
import {getText} from "../../../bookingEngine/services/configuration.service";
import {urlParametersConfig, urlRefineParametersConfig} from "../../../config/form";

export interface ICheckOption {
    code: string;
    label: string;
    entityNumber?: number;
    disabled?: boolean;
}

interface ICheckFilterItemProps {
    name: string;
    options?: ICheckOption[];
    onChange?: ChangeEventHandler<HTMLInputElement>;
    filterStore?: FilterStore;
    searchType?: string;
    hideEntityNumber?: boolean;
}

/**
 * the check boxes filter component
 */
@inject("filterStore")
@observer
export default class CheckFilterItem extends Component<ICheckFilterItemProps> implements IFilterItemComponent {

    constructor(props: Readonly<ICheckFilterItemProps>) {
        super(props);
        this.props.filterStore.checkOptionStore.addOptions(this.props.name, this.buildInitialCheckOption());
    }

    /**
     * the render
     */
    public render(): JSX.Element {
        const {options, name, hideEntityNumber} = this.props;
        const optionStore = this.props.filterStore.checkOptionStore;
        const addDestination = this.addDestination(name);
        return (
            <>
                {options.map((option: ICheckOption) => {
                    let entityNumber = option.entityNumber;
                    let disabled = false;
                    if (entityNumber && entityNumber <= 0) {
                        entityNumber = 0;
                        disabled = !optionStore.isCheck(name, option.code);
                    }
                    if (option.disabled) {
                        disabled = true;
                    }
                    setTimeout(() => optionStore.setDisabled(name, option.code, disabled), 20);
                    return <div
                        key={name + "_" + option.code}
                        className={"checkboxes-wrap"}>
                        <div className={"elem-orx-checkboxes" + (disabled ? " disabled" : "")}>
                            <div className="orx-checkboxes-content">
                                <label className={"orx-checkboxes-label"}>
                                    <input
                                        type="checkbox"
                                        name={name}
                                        id={name + "_" + option.code}
                                        value={option.code}
                                        checked={optionStore.isCheck(name, option.code)}
                                        onChange={this.handleCheckChange(option)}
                                        disabled={disabled}
                                    />
                                    <span className={"orx-checkboxes-input" +
                                           (addDestination && this.spacesCount(option.code) !== "" ? " space-" + this.spacesCount(option.code) : "")}>
                                        <span className="checked"/>
                                    </span>
                                    <span className="label-txt">{option.label}</span>
                                    {
                                        !hideEntityNumber &&
                                            <span className="label-number">({entityNumber})</span>
                                    }
                                </label>
                            </div>
                        </div>
                    </div>;
                }
                )}
                <div className="link-wrap">
                    {!optionStore.isAllCheck(name) && <span className="link" onClick={() => {
                        this.handleAllCheck(name);
                        setTimeout((evt: any) => this.props.onChange(evt), 100);
                    }}>{getText("filter.all.check.label")}</span>
                    }
                    {optionStore.isAllCheck(name) && <span className="link" onClick={() => {
                        this.handleAlluncheck(name);
                        setTimeout((evt: any) => this.props.onChange(evt), 100);
                    }}>{getText("filter.all.uncheck.label")}</span>
                    }
                </div>
            </>
        );
    }

    /**
     * add destination
     * @param name
     * @param value
     * @private
     */
    private addDestination(name: string): boolean {
        const destination = getUrlVar()[urlParametersConfig.destination];
        const to = getUrlVar()[urlParametersConfig.to];
        if (name === urlRefineParametersConfig.destination && to && to !== "" && (!destination || destination === "")) {
            return true;
        }
        return false;
    }

    /**
     * add space
     * @param value
     * @private
     */
    private spacesCount(value: string): string {
        if (value && value.indexOf(".") !== -1) {
            return `${value.split(".").length}`;
        }
        return "";
    }

    /**
     * handleAllCheck.
     * @private
     */
    private handleAllCheck(name: string) {
        this.props.filterStore.checkOptionStore.handleAllCheck(name, true);
    }

    /**
     * handleAllCheck.
     * @private
     */
    private handleAlluncheck(name: string) {
        this.props.filterStore.checkOptionStore.handleAllCheck(name, false);
        return (evt: any) => {
            setTimeout(() => this.props.onChange(evt), 20);
        };
    }

    /**
     * action when the checkbox change its state
     * @param option
     */
    private handleCheckChange(option: ICheckOption) {
        return (evt: ChangeEvent<HTMLInputElement>) => {
            this.props.filterStore.checkOptionStore.toggleCheck(this.props.name, option.code);
            if (this.props.onChange) {
                setTimeout(() => this.props.onChange(evt), 20);
            }
        };
    }

    /**
     * build checked option for the store
     */
    private buildInitialCheckOption(): IValueCheckable[] {
        return this.props.options.map((option: ICheckOption) => {
            const parameters = getUrlVar();
            const inputName = this.props.name;
            let check = false;
            const inputValue = option.code;
            const searchType = this.props.searchType;
            if (parameters[inputName]) {
                const values = parameters[inputName].split(searchType);
                check = values.some((v: string) => v === inputValue);
                if (!check) {
                    check = inputValue === parameters[inputName];
                }
            }
            return {value: inputValue, check};
        });
    }
}
