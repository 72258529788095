import * as React from "react";
import {ChangeEvent, Component} from "react";
import {getDays, getMonths, getYears, OptionType} from "../../../config/pax";
import {observer} from "mobx-react";
import {SearchEngineStore} from "../../SearchEngineStore";
import {urlParametersConfig} from "../../../config/form";

interface IChildrenBirthDateProps {
    searchEngineStore: SearchEngineStore;
    index: number;
    children?: React.ReactNode;

}

@observer
export default class ChildBirthDate extends Component<IChildrenBirthDateProps> {

    constructor(props: IChildrenBirthDateProps) {
        super(props);
    }

    public render(): JSX.Element {
        const {index, searchEngineStore} = this.props;
        const name = `${urlParametersConfig.birthdates}_${index + 1}`;
        const date = searchEngineStore.values[name][0];
        const day = date.split("/")[0];
        const month = date.split("/")[1];
        const year = date.split("/")[2];
        const days = getDays(month, year);
        const months: OptionType[] = getMonths();
        const years: OptionType[] = getYears();

        return (
            <div className="children-item">
                <div className="label">Date de naissance de l'enfant {index + 1}</div>
                <div className="row">
                    <div className="col-4">
                        <div className="select-wrap">
                            <select className="input-style select" id={`childBirthday_${index}`}
                                onChange={(event: ChangeEvent<HTMLSelectElement>) => this.handleDayChange(name, event.currentTarget.value)}
                                defaultValue={day}
                            >
                                {days.map((day: OptionType) => (<option key={day.value} value={day.value}>{day.label}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="select-wrap">
                            <select className="input-style select" id={`childBirthMonth_${index}`}
                                onChange={(event: ChangeEvent<HTMLSelectElement>) => this.handleMonthChange(name, event.currentTarget.value)}
                                defaultValue={month}
                            >
                                {months.map((month: OptionType) => (<option key={month.value} value={month.value}>{month.label}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="select-wrap">
                            <select className="input-style select" id={`childBirthYear_${index}`}
                                onChange={(event: ChangeEvent<HTMLSelectElement>) => this.handleYearChange(name, event.currentTarget.value)}
                                defaultValue={year}
                            >
                                {years.map((year: OptionType) => (<option key={year.value} value={year.value}>{year.label}</option>))}
                            </select>
                        </div>
                    </div>
                    <input type={"hidden"} name={name} value={date}/>
                </div>
            </div>
        );
    }

    private handleDayChange(name: string, day: string): void {
        const newDate = this.getChildBirthDate(name, day);
        this.props.searchEngineStore.setValue(name, newDate);
    }

    private handleMonthChange(name: string, month: string): void {
        const newDate = this.getChildBirthDate(name, null, month);
        this.props.searchEngineStore.setValue(name, newDate);
    }

    private handleYearChange(name: string, year: string): void {
        const newDate = this.getChildBirthDate(name, null, null, year);
        this.props.searchEngineStore.setValue(name, newDate);
    }

    private getChildBirthDate(name: string, replaceDay?: string, replaceMonth?: string, replaceYear?: string): string {
        const birthDateChild = this.props.searchEngineStore.values[name][0];
        const birthDateChildAge = birthDateChild.split("/");
        return (replaceDay || birthDateChildAge[0]) + "/" + (replaceMonth || birthDateChildAge[1]) + "/" + (replaceYear || birthDateChildAge[2]);
    }
}

