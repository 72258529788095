import {action, computed, observable} from "mobx";
import moment = require("moment");
import DayPicker from "react-day-picker";

export const dateSearchItemConfiguration = {
    monthYearPattern: "MM-YYYY"
};

/**
 * the store for the date search item
 */
export default class DateSearchItemStore {
    @observable private selectedDates: Date[];
    @observable private oldSelectedDates: Date[];
    @observable private adjustDateBy: number;
    @observable private emptyLabel: string;
    @observable private readonly selectedMonths: string[];

    /**
     * constructor
     */
    constructor() {
        this.emptyLabel = "";
        this.selectedMonths = [];
        this.selectedDates = [];
        this.oldSelectedDates = [];
    }

    /**
     * return the current active start date, undefined if no start date is activated
     */
    @computed
    public get activeDepartureDate() {
        return (this.selectedMonths.length === 0 && this.selectedDates.length !== 0) ? this.selectedDates[0] : undefined;
    }

    /**
     * return the current active end date, undefined if no end date is activated
     */
    @computed
    public get activeArrivalDate() {
        return (this.selectedMonths.length === 0 && this.selectedDates.length !== 0)  ? this.selectedDates[this.selectedDates.length - 1] : undefined;
    }

    /**
     * return the old selected start date, undefined if no old selected start date is activated
     */
    @computed
    public get oldDepartureDate() {
        return (this.selectedMonths.length === 0 && this.oldSelectedDates.length !== 0) ? this.oldSelectedDates[0] : undefined;
    }

    /**
     * return the old selected end date, undefined if no old selected end date is activated
     */
    @computed
    public get oldArrivalDate() {
        return (this.selectedMonths.length === 0 && this.oldSelectedDates.length !== 0)  ? this.oldSelectedDates[this.oldSelectedDates.length - 1] : undefined;
    }

    /**
     * Returns the current selected start date value
     */
    @computed
    public get isSelectedValue(): boolean {
        return ( this.selectedMonths.length !== 0 || typeof (this.activeDepartureDate) !== undefined );
    }

    /**
     * get the label for no value option
     */
    @computed
    public get empty() {
        return this.emptyLabel;
    }

    /**
     * get the the valid dates with current start date and adjust value
     */
    @computed
    public get includedDates(): { from: Date; to: Date } {
        if (this.selectedDates.length !== 0 && this.adjustDateBy !== 0 && this.selectedMonths.length === 0) {
            const from = moment(this.activeDepartureDate);
            const to = moment(this.activeDepartureDate);
            from.add(-this.adjustDateBy, "day");
            to.add(this.adjustDateBy, "day");
            return {from: from.toDate(), to: to.toDate()};
        } else {
            return undefined;
        }
    }

    /**
     * the adjust value
     */
    @computed
    public get adjustValue() {
        return this.adjustDateBy;
    }

    @computed
    public get visibleMonthsValue() {
        if (this.selectedMonths.length !== 0) {
            return moment(this.allMonthsMinDepartureDate).format("MMMM YYYY");
        } else {
            return undefined;
        }
    }

    @computed
    public get visibleMonthValue() {
        if (this.selectedMonths.length === 1) {
            return moment(this.allMonthsMinDepartureDate).format("MMM YYYY");
        } else {
            return undefined;
        }
    }

    /**
     * the selected months
     */
    @computed
    public get months(): string[] {
        return this.selectedMonths;
    }

    /**
     * get the min departure date when date selected by month
     */
    @computed
    public get allMonthsMinDepartureDate(): Date {
        if (this.months.length !== 0) {
            const date = moment(this.months[0], dateSearchItemConfiguration.monthYearPattern);
            date.add(-this.adjustValue, "day");
            return date.toDate();
        } else {
            return undefined;
        }
    }

    /**
     * get the max departure date when date selected by month
     */
    @computed
    public get allMonthsMaxDepartureDate(): Date {
        if (this.months.length !== 0) {
            const date = moment(this.months[this.months.length - 1], dateSearchItemConfiguration.monthYearPattern);
            date.endOf("month");
            date.add(this.adjustValue, "day");
            return date.toDate();
        } else {
            return undefined;
        }
    }

    /**
     * change the selected date
     * @param date
     */
    @action.bound
    public selectDate(date: Date) {
        if (date) {
            this.clearSelectedMonths();
        }
        this.selectedDates = [date];
    }
    /**
     * returns if selecting the departure Date
     *  @param from the current departure date
     *  @param to the current arrival date
     *  @param day the new date to select
     */
    @action.bound
    public isSelectingDepartureDate(from: Date, to: Date, day: Date): boolean {
        const isBeforeFirstDay = from && DayPicker.DateUtils.isDayBefore(day, from);
        const isValidRangeSelected = from && to && (from < to);
        return !from || isBeforeFirstDay || isValidRangeSelected;
    }
    /**
     * change the selected range date
     * @param date
     */
    @action.bound
    public updateRangeDate(date: Date) {
        const from = this.activeDepartureDate;
        const to =  this.activeArrivalDate;
        this.oldSelectedDates = [from, to];
        if (from && to && date >= from && date <= to) {
            this.selectedDates = [];
            return;
        }
        if (this.isSelectingDepartureDate(from, to, date)) {
            this.selectedDates = [date];
        } else {
            this.selectedDates = [from, date];
        }
    }

    /**
     * clear selected dates array
     */
    public clearSelectedDates() {
        if (this.selectedDates.length !== 0) {
            this.selectedDates.splice(0, this.selectedDates.length);
        }
    }
    /**
     * update selected dates array
     */
    public updateSelectedDatesWithOldSelectedDates() {
        this.selectedDates = this.oldSelectedDates;
    }

    /**
     * set the nb of day valid around the date
     * @param adjust
     */
    @action.bound
    public setAdjustDateBy(adjust: number) {
        this.adjustDateBy = adjust;
    }

    /**
     * Reset adjust date value
     */
    @action.bound
    public resetAdjustDate() {
        this.adjustDateBy = 0;
    }

    /**
     * change
     * @param emptyLabel
     */
    @action.bound
    public setEmptyLabel(emptyLabel: string) {
        this.emptyLabel = emptyLabel;
    }

    /**
     * add month to selected months and all month between extreme value
     * @param month
     */
    @action.bound
    public addMonth(month: string) {
        if (this.months.indexOf(month) === -1) {
            this.months.push(month);
            this.guaranteeMonthContinuity();
        }
    }

    /**
     * remove month to selected months
     * @param month
     */
    @action.bound
    public removeMonth(month: string) {
        const monthIndex = this.months.indexOf(month);
        if (monthIndex !== -1) {
            this.months.splice(monthIndex, 1);
            this.guaranteeMonthContinuity();
        }
    }

    /**
     * set all months
     * @param months
     */
    @action.bound
    public setAllMonths(months: string[]) {
        this.cleanMonths();
        this.selectedMonths.push(...months);
    }

    /**
     * clear months array
     */
    @action.bound
    public cleanMonths() {
        this.selectedMonths.length = 0;
    }

    /**
     * clear selected months array
     */
    private clearSelectedMonths() {
        if (this.selectedMonths.length !== 0) {
            this.selectedMonths.splice(0, this.selectedMonths.length);
        }
    }

    /**
     * guarantee all month between extreme month are selected
     */
    private guaranteeMonthContinuity() {
        if (this.months.length > 1) {
            const sorted = this.months.sort((d1: string, d2: string) => {
                const diff = moment(d1, dateSearchItemConfiguration.monthYearPattern).diff(moment(d2, dateSearchItemConfiguration.monthYearPattern));
                if (diff === 0) {
                    return 0;
                } else {
                    return diff > 0 ? 1 : -1;
                }
            });
            const min = moment(sorted[0], dateSearchItemConfiguration.monthYearPattern);
            const max = moment(sorted[sorted.length - 1], dateSearchItemConfiguration.monthYearPattern);
            this.clearSelectedMonths();
            while (max >= min) {
                this.months.push(min.format(dateSearchItemConfiguration.monthYearPattern));
                min.add(1, "month");
            }
        }
    }
}
