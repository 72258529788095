import "intersection-observer";
import * as lozad from "lozad";

// Local variable
let lazy: lozad.Observer;

/**
 * Initialize the lazy load
 */
export function initLazyLoading() {
    lazy = lozad();

    lazy.observe();
}

/**
 * Observes newly added elements as well
 */
export function observesLazyImage() {
    lazy.observe();
}

/**
 * Load the image with lazy
 * @param elem
 */
export function triggerLoadLazyImage(elem: Element) {
    lazy.triggerLoad(elem);
}
