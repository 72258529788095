import {action, computed, observable} from "mobx";

export const valueSeparator = ",";

/**
 * the store for the range filter
 */
export class RangeFilterStore {
    private readonly minValue: number;
    private readonly maxValue: number;
    @observable private from: number;
    @observable private to: number;

    /**
     * the constructor
     * @param from start value for the lower boundary
     * @param to start value for the upper boundary
     * @param minValue the lower limit
     * @param maxValue the upper limit
     */
    constructor(from: number, to: number, minValue: number, maxValue: number) {
        this.from = from;
        this.to = to;
        this.maxValue = maxValue;
        this.minValue = minValue;
    }

    /**
     * get the lower boundary
     */
    @computed
    public get fromValue(): number {
        return this.from;
    }

    /**
     * get the upper boundary
     */
    @computed
    public get toValue(): number {
        return this.to;
    }

    /**
     * the built value to submit
     */
    @computed
    public get submitValue(): string {
        if (this.minValue === this.from && this.maxValue === this.to) {
            return null;
        } else if (this.maxValue === this.to) {
            return String(this.from);
        } else {
            return String(this.from) + valueSeparator + this.to;
        }

    }

    /**
     * set the new lower boundary
     * @param from
     */
    @action.bound
    public updateFromValue(from: number) {
        this.from = from;
    }

    /**
     * set the new upper boundary
     * @param to
     */
    @action.bound
    public updateToValue(to: number) {
        this.to = to;
    }

    /**
     * reset range to initial value
     */
    @action.bound
    public reset() {
        this.from = this.minValue;
        this.to = this.maxValue;
    }
}