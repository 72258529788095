import * as $$ from "jquery-selector-cache";

/**
 * Initialization Function Header action
 *
 */

export function initHeaderNavBar() {

    // Exit when component don't exist
    if (!$$(".cpt-header-nav-bar").length) {
        return;
    }

    $$(".cpt-header-nav-bar").on("click.clickHeaderNav", ".nav-bar-item", (evt: JQuery.ClickEvent) => {

        const currentTarget = $(evt.currentTarget);
        const currentTargetSearchBtn = currentTarget.hasClass("search-btn-ctrl");
        const currentTargetRipeningBtn = currentTarget.hasClass("ripening-btn-ctrl");
        const currentTargetSortBtn = currentTarget.hasClass("sort-btn-ctrl");
        const delegateTarget = $(evt.delegateTarget);

        $$(".nav-bar-item").removeClass("active");
        $$("body").removeClass("body-no-scroll");
        if (currentTargetSearchBtn) {
            $$("body").addClass("body-no-scroll");
            currentTarget.addClass("active");
            delegateTarget.addClass("fixed");
            if (!$$(".cpt-search-engine").hasClass("d-none")) {
                $$("body").removeClass("body-no-scroll");
                currentTarget.removeClass("active");
                delegateTarget.removeClass("fixed");
            }
            $$(".cpt-search-engine").toggleClass("d-none");
            $$(".cpt-filter-panel").addClass("d-none");
            $$(".cpt-sort-panel").addClass("d-none");
        } else if (currentTargetRipeningBtn) {
            $$("body").addClass("body-no-scroll");
            currentTarget.addClass("active");
            delegateTarget.addClass("fixed");
            if (!$$(".cpt-filter-panel").hasClass("d-none")) {
                $$("body").removeClass("body-no-scroll");
                currentTarget.removeClass("active");
                delegateTarget.removeClass("fixed");
            }
            $$(".cpt-search-engine").addClass("d-none");
            $$(".cpt-filter-panel").toggleClass("d-none");
            $$(".cpt-sort-panel").addClass("d-none");
        } else if (currentTargetSortBtn) {
            $$("body").addClass("body-no-scroll");
            currentTarget.addClass("active");
            delegateTarget.addClass("fixed");
            if (!$$(".cpt-sort-panel").hasClass("d-none")) {
                $$("body").removeClass("body-no-scroll");
                currentTarget.removeClass("active");
                delegateTarget.removeClass("fixed");
            }
            $$(".cpt-search-engine").addClass("d-none");
            $$(".cpt-filter-panel").addClass("d-none");
            $$(".cpt-sort-panel").toggleClass("d-none");
        }

    });
}
