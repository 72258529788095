export interface IBreakpointsConfig extends IBreakpointsOptions {
    readonly xs: IBreakpointsBoundary;
    readonly sm: IBreakpointsBoundary;
    readonly md: IBreakpointsBoundary;
    readonly lg: IBreakpointsBoundary;
    readonly xl: IBreakpointsBoundary;
}

/**
 * Configure the breakpoints
 * @type IBreakpointsConfig
 */
export const breakpointsConfig: IBreakpointsConfig = {
    xs: {min: 0, max: 575},
    sm: {min: 576, max: 767},
    md: {min: 768, max: 991},
    lg: {min: 992, max: 1370},
    xl: {min: 1370, max: Infinity}
};
