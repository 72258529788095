import * as $$ from "jquery-selector-cache";

/**
 * Initialize the module
 */
export function initb2bBlock() {
    // Exit when component don't exist
    if (!$$(".cpt-block-b2b").length) {
        return;
    }
    $$(".cpt-block-b2b ").on("click", ".button-wrap", () => {
        // const currentTarget = $(evt.currentTarget);
    });
    $("#blockB2b").on("submit", handleB2bFormSubmit);
}
/**
 * handleB2bFormSubmit
 * @param evt
 */
function handleB2bFormSubmit(evt: JQuery.TriggeredEvent<HTMLElement>) {
    evt.preventDefault();
    const form = $(evt.currentTarget);
    window.location.href = window.location.pathname + "?" + form.serialize()
        .replace(/ /g, "+")
        .replace(/%20/g,"");
}
