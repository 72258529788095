import * as React from "react";
import {Component} from "react";
import {observer} from "mobx-react";
import {SearchEngineStore} from "../../SearchEngineStore";
import {ISearchItemProps} from "../index";

import {urlParametersConfig} from "../../../config/form";
import {getDays, getMonths, getYears} from "../../../config/pax";
import ChildBirthDate from "./ChildBirthDate";
import {getUrlVar} from "orxapi.tools.url";
import Icon from "../../../bookingEngine/components/Icon";

interface IMoreLessNumberRowProps extends ISearchItemProps {
    searchEngineStore: SearchEngineStore;
    label?: string;
    subLabel?: string;
    title?: string;
    legend?: string;
    maxValue?: number;
    minValue?: number;
    classPrefix?: string;
    moreThanMaxTotal?: boolean;
    totalNumber?: number;
    withAgeSelection?: boolean
}

/**
 * the more less button row for number
 */
@observer
export default class MoreLessNumberRow extends Component<IMoreLessNumberRowProps> {

    constructor(props: IMoreLessNumberRowProps) {
        super(props);
    }

    /**
     * the renderer
     */
    public render(): JSX.Element {
        const searchEngineStore = this.props.searchEngineStore;
        const name = this.props.name;
        const moreThanMaxTotal = this.props.moreThanMaxTotal || false;
        const totalNumber = this.props.totalNumber;
        const value = Number(searchEngineStore.values[name][0]);
        const prefix = this.props.classPrefix || "number-row";
        const canLess = value > (this.props.minValue || 0);
        const canMore = value < (this.props.maxValue || Number.POSITIVE_INFINITY) && !moreThanMaxTotal;
        const withAgeSelection = this.props.withAgeSelection || false;
        const months = getMonths();
        const years = getYears();
        const days = getDays(months[0].value, years[0].value);
        return (
            <div className={prefix + "-container"}>
                <div className="spinner-content">
                    <div className="bt-label">
                        {this.props.title &&
                            <div className={prefix + "-title"}>{this.props.title}</div>
                        }
                        <span className="legend">{this.props.legend}</span>
                    </div>
                    <div className="spinner-block">
                        <span className="spinner-button spinner-remove" onClick={() => {
                            if (canLess) {
                                searchEngineStore.setValue(name, String(value - 1));
                            }
                        }}>
                            <div className="icon-wrap icon-less">
                                <Icon icon="w"/>
                            </div>
                        </span>
                        <input type="text" id="ipt_bookingAdults" className="spinner-value nb-adults" value={value} readOnly={true}/>
                        <span className="spinner-button spinner-add" onClick={() => {
                            if (canMore) {
                                searchEngineStore.setValue(name, String(value + 1));
                            }
                        }}>
                            <div className="icon-wrap icon-more">
                                <Icon icon="n"/>
                            </div>
                        </span>
                    </div>
                </div>

                {totalNumber > 0 &&
                    <input type={"hidden"} name={this.props.name} value={value}/>
                }
                {withAgeSelection &&
                    <div className="age-children-selection">
                        {Array(value).fill(1).map((value: number, index: number) => {
                            const name = urlParametersConfig.birthdates + "_" + (index + 1);
                            let birthDate = getUrlVar()[name] || "";
                            if (birthDate.split("/").length !== 3) {
                                birthDate = days[0].value + "/" + months[0].value + "/" + years[0].value;
                            }
                            this.props.searchEngineStore.setValue(name, birthDate);
                            return (
                                <ChildBirthDate key={`children-selection-${index}`} index={index} searchEngineStore={searchEngineStore}/>
                            );
                        })
                        }
                    </div>
                }
            </div>
        );
    }
}