import * as $$ from "jquery-selector-cache";
import {handleToScroll} from "orxapi.tools.toscroll";
import {addTrackingInfoProduct} from "../tracking/tracking";
import {sendProductDetailShownTracking} from "../../_core/scripts/specific/tools/Tracking";

/**
 * Initialize the module
 */
export function initScrollLink() {
    // Exit when component don't exist
    if (!$$(".scroll-link").length) {
        return;
    }

    $$(".scroll-link").on("click.toggleLink", handleToScroll);
}

/**
 * initBookingPrice
 */
export function initBookingPrice() {
    const hash = window.location.hash.replace("#", "");
    const pid = $("#cptBookingProduct").data("productId");
    const query = "?s_pid=" + pid + "&" + hash;
    $.ajax("/ajax/product/price" + query)
        .done((response: string) => {
            if (response && response.trim().length > 0 && $(response).find(".real-price").length > 0) {
                const priceDataContainer = $$(".cpt-booking-sticky-price");
                priceDataContainer.html($(response).find(".cpt-booking-sticky-price").html());
                priceDataContainer.find(".scroll-link").on("click.toggleLink", handleToScroll);
                const promoteContent = $(response).find(".cpt-promote-pdt");
                $$(".cpt-informations-product").prepend(promoteContent);
                const trackingData = $(response).find(".next-departure-block");
                addTrackingInfoProduct(trackingData);
                const price = $(trackingData).data("price");
                sendProductDetailShownTracking(pid, price);
            }
        });
}

/**
 * Initialize sticky price
 */
export function initShowBtnStickyPrice() {
    $(window).on("scroll", () => {
        const bookingPricePosition = $$(".bp-booking-price").offset().top;
        const windowScrollPosition = $(window).scrollTop();
        const windowHeight = $(window).height();

        if (windowScrollPosition >= (bookingPricePosition - windowHeight)) {
            $$(".cpt-booking-sticky-price").addClass("d-none");
        } else {
            $$(".cpt-booking-sticky-price").removeClass("d-none");
        }

    });
}
