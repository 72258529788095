import * as $$ from "jquery-selector-cache";
import "slick-carousel";
import {diaporamaConfig} from "./diaporamaConfig";

/**
 * Initialize the component
 */
export function initDiaporama(selector: string, diaporamaConfigKey: string) {
    if (!selector) {
        selector = ".cpt-diaporama";
    }
    if (!diaporamaConfigKey) {
        diaporamaConfigKey = "default";
    }

    // Exit when component don't exist
    if (!$$(".cpt-diaporama").length) {
        return;
    }

    initSlick(diaporamaConfigKey, selector);
}

/**
 * Initialize the slick plug-in
 * @param config
 * @param dataNameSelector
 */
function initSlick(config: string, dataNameSelector: string) {
    $(dataNameSelector).slick(diaporamaConfig[config]);
}