import * as $$ from "jquery-selector-cache";

/**
 * Initialization Accordion ORXAPI
 */
export function initOrxAccordion() {
    // Exit when component don't exist
    if (!$$(".cpt-orx-accordion").length) {
        return;
    }

    $$(".cpt-orx-accordion").on("click.accordionTitle", ".orx-accordion-title", handleToggleAccordion);
    $(window).on("resize", handleResetAccordion);

}

/**
 * handleToggleAccordion
 * @param evt
 */
function handleToggleAccordion(evt: JQuery.ClickEvent) {
    const target = $(evt.currentTarget);
    const delegateTarget = $(evt.delegateTarget);
    const contentHeight = target.next(".orx-accordion-mask").find(".orx-accordion-content").outerHeight();

    if (target.parent().hasClass("open")) {
        target.parent().removeClass("open");
        target.next(".orx-accordion-mask").css("height", "0");
    } else {
        delegateTarget.find(".orx-accordion-container").removeClass("open");
        delegateTarget.find(".orx-accordion-mask").css("height", "0");
        target.parent().toggleClass("open");
        target.next(".orx-accordion-mask").css("height", contentHeight);
    }
}

/**
 * Initialization Listen Reset Accordion
 */
function handleResetAccordion() {
    if ($$(".cpt-orx-accordion").find(".orx-accordion-container").hasClass("open")) {
        $$(".cpt-orx-accordion").find(".open").find(".orx-accordion-mask").css("height", "auto");
    }
}