import * as $$ from "jquery-selector-cache";

/**
 * Initialize the module
 */
export function initOrxPopin() {
    // Exit when component don't exist
    if (!$$(".cpt-orx-popin").length) {
        return;
    }

    $$(".cpt-orx-popin").on("click", ".overlay-popin", (evt: JQuery.ClickEvent) => {
        const currentTarget = $(evt.currentTarget);
        const delegateTarget = $(evt.delegateTarget);

        $$(".btn-close-popin").removeClass("fade-out-up-animate").addClass("fade-out-animate").next().removeClass("fade-in-animate").addClass("fade-out-animate");
        currentTarget.removeClass("fade-in-animate").addClass("fade-out-animate").animate({top: 0}, 190, () => {
            resetInitialState(delegateTarget);
        });

        deactivateElements();
    });

    $$(".cpt-orx-popin").on("click", ".btn-close-popin", (evt: JQuery.ClickEvent) => {
        const currentTarget = $(evt.currentTarget);
        const delegateTarget = $(evt.delegateTarget);

        currentTarget.removeClass("fade-out-up-animate").addClass("fade-out-animate").next().removeClass("fade-in-animate").addClass("fade-out-animate");
        delegateTarget.find(".overlay-popin").removeClass("fade-in-animate").addClass("fade-out-animate").animate({top: 0}, 190, () => {
            resetInitialState(delegateTarget);
        });

        deactivateElements();
    });
}

/**
 * Deactivate the elements
 */
function deactivateElements() {
    $$(".share-block").removeClass("active");
    $$(".cpt-promote-pdt").removeClass("active");
    $$(".video-button").removeClass("active");
}

/**
 * Reset the initial state
 * @param delegateTarget
 */
function resetInitialState(delegateTarget) {
    $$("body").removeClass("body-no-scroll");
    delegateTarget.addClass("d-none");
}