import * as React from "react";
import {Component} from "react";
import {getFilterItemComponent, IFilterItem} from "../FilterItem";
import {getUrlVar} from "orxapi.tools.url";
import FilterStore from "../FilterStore";
import {inject} from "mobx-react";
import {SyntheticEvent} from "react";
import {
    addHideQueryInputs,
    filterParameters,
    ISearchType,
    submitFormWithAjax
} from "orxapi.widgets.framework/dist/tools/formTools";
import SubmitButton from "orxapi.widgets.framework/dist/components/button/SubmitButton";
import {urlParametersConfig} from "../../config/form";
import {sendSearchRequestTracking, sendSearchRequestTrackingLanding, sendSearchServedTracking} from "../../tools/Tracking";

export interface IFilterEngine {
    currentQuery: string;
    filterItems: IFilterItem[];
    searchTypes: ISearchType[];
}

interface IFilterEngineProps {
    title?: string;
    closeLabel?: string;
    submitLabel?: string;
    submitUrl?: string;
    filterEngine: IFilterEngine;
    defaultSubmit?: boolean;
    ajaxTarget?: string;
    autoSubmit?: boolean;
    filterStore?: FilterStore;
    reinitText?: string;
}

/**
 * layout for the filter engine
 */
@inject("filterStore")
export default class FilterEngineLayout extends Component<IFilterEngineProps> {
    private formRef: HTMLFormElement;

    constructor(props: Readonly<IFilterEngineProps>) {
        super(props);
        this.ajaxCallBack = this.ajaxCallBack.bind(this);
    }
    componentDidMount() {
        // This code will run after the component has been mounted
        sendSearchRequestTrackingLanding();
        sendSearchServedTracking();
    }
    /**
     * the renderer
     */
    public render(): JSX.Element {
        const {title, filterEngine, submitUrl, submitLabel, ajaxTarget, reinitText} = this.props;
        const useDefaultSubmit = this.props.defaultSubmit === undefined ? true : this.props.defaultSubmit;
        return (
            <>
                <h3 className="h3-style">
                    {title}
                </h3>
                <div className="link-wrap">
                    {reinitText &&
                    (
                        <div
                            id="reinitButton"
                            onClick={() => {
                                this.props.filterStore.resetAllItem();
                                setTimeout(() => this.submitEvent(), 100);
                            }}
                        >
                            {reinitText}
                        </div>
                    )
                    }
                </div>
                <form id="filter-panel-content" ref={(element: HTMLFormElement) => this.formRef = element}
                    action={submitUrl} className="filter-panel-content"
                    {...(useDefaultSubmit ? {
                        onSubmit: this.tryToSubmitForm(ajaxTarget, filterEngine)
                    } : {})}>
                    {addHideQueryInputs(decodeURIComponent(filterEngine.currentQuery))}
                    {filterEngine.filterItems.map(this.createFilterItem(filterEngine.searchTypes)
                    )}
                    <div className="button-container">
                        <SubmitButton
                            label={submitLabel}
                            wrapClass={"d-lg-none"}
                            buttonClass={"elem-button-primary"}
                        />
                    </div>
                    {this.addUseAdvParamInput()}
                </form>
            </>
        );
    }

    private tryToSubmitForm(ajaxTarget: string, filterEngine: IFilterEngine) {
        return (evt: SyntheticEvent) => {
            evt.preventDefault();
            ajaxTarget ? submitFormWithAjax(evt, filterEngine.searchTypes, this.ajaxCallBack) : this.submitForm();
        };
    }

    private submitForm() {
        const form = $(this.formRef);
        const query = filterParameters(form.serializeArray(), this.props.filterEngine.searchTypes);
        window.location.href = form.prop("action") + (query ? ("?" + new URLSearchParams(query).toString()) : "");
    }

    /**
     *  * add input from the base query if useAdv=true
     */

    protected addUseAdvParamInput() {
        const useAdvValue = getUrlVar()[urlParametersConfig.useAdv];
        return (
            <>
                {useAdvValue === "true" && <input name={urlParametersConfig.useAdv} type="hidden" value={useAdvValue}/>}
            </>
        );
    }

    /**
     * create the filter item
     * @param searchTypes
     */
    private createFilterItem(searchTypes: ISearchType[]) {
        return (item: IFilterItem, index: number) => {
            const component = getFilterItemComponent(item, searchTypes, this.props.autoSubmit ? this.dispatchSubmitEvent() : null);
            if (component) {
                return (
                    <div key={"itemFilter_" + index} className="filter-panel-item">
                        <div className="panel-item-title">{item.label}</div>
                        {component}
                    </div>
                );
            }
        };
    }

    /**
     * return submit event function
     */
    private dispatchSubmitEvent() {
        return () => {
            return this.submitEvent();
        };
    }

    /**
     * submit event
     */
    private submitEvent() {
        if (this.formRef) {
            this.submitForm();
            const formData = new FormData(this.formRef);
            sendSearchRequestTracking(formData);
        }
    }

    /**
     * call back on ajax response
     * @param response
     */
    private ajaxCallBack(response: any) {
        $(this.props.ajaxTarget).html(response);
    }

}
