import * as $$ from "jquery-selector-cache";
import { TabsManager } from "orxapi.plugins.tabs";

/**
 * Initialize the module
 */
export function initProductInformation() {
    // Exit when component don't exist
    if (!$$(".cpt-tabs-product").length) {
        return;
    }

    new TabsManager({
        createTabsFromContent: true,
        contentsClass: "product-tabs-content",
        selectTab: 0
    });

}