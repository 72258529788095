import * as React from "react";
import {Component} from "react";
import * as DOMPurify from "dompurify";
import {Range} from "rc-slider";
import {RangeFilterStore, valueSeparator} from "./RangeFilterStore";
import {inject, observer} from "mobx-react";
import {getUrlVar} from "orxapi.tools.url";
import FilterStore from "../../FilterStore";
import {IFilterItemComponent} from "../index";

interface IRangeItemProps {
    name: string;
    min: number;
    max: number;
    textPattern?: string;
    step?: number;
    onChange?: (evt: any) => void;
    filterStore?: FilterStore;
}

/**
 * the rang filter component
 */
@inject("filterStore")
@observer
export default class RangeFilterItem extends Component<IRangeItemProps> implements IFilterItemComponent {
    private readonly rangeStore: RangeFilterStore;

    constructor(props: Readonly<IRangeItemProps>) {
        super(props);
        const parameters = getUrlVar();
        const currentValue = parameters[this.props.name];
        let minimumStart = this.props.min;
        let maximumStart = this.props.max;
        if (currentValue) {
            const values = currentValue.split(valueSeparator);
            minimumStart = Number(values[0]) || minimumStart;
            maximumStart = Number(values[1]) || maximumStart;
        }
        this.rangeStore = new RangeFilterStore(minimumStart, maximumStart, this.props.min, this.props.max);
        this.props.filterStore.addRangeStore(this.rangeStore);
    }

    /**
     * the render
     */
    public render(): JSX.Element {
        const {min, max, name, textPattern} = this.props;
        const step = this.props.step || 1;
        return (
            <div className="cpt-slider-range">
                <Range
                    min={min}
                    max={max}
                    value={[this.rangeStore.fromValue, this.rangeStore.toValue]}
                    step={step}
                    allowCross={false}
                    onChange={(value: any) => this.handleOnValueChange(value)}
                    {...(this.props.onChange ? {onAfterChange: this.props.onChange} : {})}
                />
                {textPattern &&
                <div
                    className="slider-range-value"
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.getRangeText(this.rangeStore.fromValue, this.rangeStore.toValue))}}/>
                }
                {this.rangeStore.submitValue &&
                <input type="hidden" name={name} value={this.rangeStore.submitValue}/>
                }

            </div>
        );
    }

    /**
     * handle the range value change
     * @param value
     */
    private handleOnValueChange(value: any) {
        this.rangeStore.updateFromValue(value[0]);
        this.rangeStore.updateToValue(value[1]);
    }

    /**
     * get the range (purified) text from the text pattern
     * @param fromValue
     * @param toValue
     */
    private getRangeText(fromValue: number, toValue: number): string {
        let finalText = this.props.textPattern
            .replace("{min}", String(fromValue))
            .replace("{max}", String(toValue));
        const showOrMore = toValue >= this.props.max;
        if (showOrMore) {
            finalText = finalText.replace(/{more:([A-z\s]+)}/, "$1");
        } else {
            finalText = finalText.replace(/{more:([A-z\s]+)}/, "");
        }
        return DOMPurify.sanitize(finalText);
    }
}