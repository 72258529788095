
import {urlParametersConfig} from "../../config/form";
import {addsCookie, getCookie} from "../Cookie";

function setCanalFromUrl() {
    const canal = new URL(window.location.href).searchParams.get("canal")
        || new URLSearchParams(window.location.hash).get("canal")
        || "";
    if (canal !== "") {
        addsCookie(urlParametersConfig.canal, canal);
    }
}

function getCanal() {
    return getCookie(urlParametersConfig.canal) || "";
}

export {
    setCanalFromUrl,
    getCanal,
};