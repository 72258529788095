import * as React from "react";
import SelectSearchItem from "./SelectSearchItem";
import DateSearchItem from "./DateSearchItem";
import PassengerSearchItem from "./PassengerSearchItem";

/**
 * interface described a search item
 */
export interface ISearchItem {
    label?: string;
    code?: string;
    type: SearchItemType;
    data: ISearchItemProps;
    disabled?: boolean;
    addClass?: string;
    onChange?: (label: string) => void;
}

/**
 * interface to describe search item props
 */
export interface ISearchItemProps {
    name: string;
    label?: string;
    manageOwnLabel?: boolean;
}

/**
 * type of search item
 */
export enum SearchItemType {
    SELECT = "select",
    DATE = "date",
    PASSENGER = "passenger",
}

/**
 * return the right component by type
 * @param type the type of component to return
 * @param data the data to fill component
 * @param label the label of search item
 * @param disabled
 * @param onChange
 * @param callback
 */
export function getSearchItemComponent(type: SearchItemType, data: any, label: string, disabled: boolean,
                                       onChange?: (label: string) => void,
                                       callback?: (label: string) => void): React.ReactNode {
    let item;

    if (type === SearchItemType.SELECT) {
        item = <SelectSearchItem label={label} {...data} disabled={disabled} onChange={onChange} callBack={callback}/>;
    } else if (type === SearchItemType.DATE) {
        item = <DateSearchItem label={label} {...data} disabled={disabled}/>;
    } else if (type === SearchItemType.PASSENGER) {
        item = <PassengerSearchItem label={label} {...data}/>;
    }
    return item;
}

/**
 * Return a search item by its code from a search item list
 * @param searchItems search items to filter
 * @param code code of the select item to return
 */
export function getSearchItemByCode(searchItems: ISearchItem[], code: string) {
    return searchItems.find((item: ISearchItem) => item.code === code);
}

/**
 * Return search items filtered by excluded codes
 * @param searchItems search items to filter
 * @param excludedCodes codes to exclude from returned search items
 */
export function getSearchItemsExcludedByCode(searchItems: ISearchItem[], excludedCodes: string[]) {
    return searchItems.filter((item: ISearchItem) => excludedCodes.indexOf(item.code) <= -1);
}
