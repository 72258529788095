import moment = require("moment");
const dateFormat = "YYYY-MM-DD HH:mm:ss";

/**
 * Initialize the component
 */
export function initResultItem() {
    const countdowns = $(".countdown");

    countdowns.each((index: number, countdown: HTMLElement) => {
        initializeCountdown($(countdown));
    });
}

/**
 * initialize Countdown
 * @param countdown
 */
export function initializeCountdown(countdown: JQuery) {
    const pushingEndDate = countdown.data("pushingEndDate");
    if (!pushingEndDate) {
        return;
    }

    const momentNow = moment();
    const momentEndDate = moment(pushingEndDate, dateFormat);
    // get remaining days
    const remainingDays = momentEndDate.diff(momentNow, "days");
    // get remaining hours and minutes
    momentEndDate.subtract(remainingDays, "day");
    const duration = moment.duration( momentEndDate.diff(momentNow) );
    const remainingHours = duration.hours();
    const remainingMinutes =  duration.minutes();

    // build text
    const minuteLabel = countdown.data("labelMinute") || "m",
        hourLabel = countdown.data("labelHour") || "h",
        dayLabel = countdown.data("labelDay") || "d";
    let text = remainingMinutes + minuteLabel;
    countdown.find(".countdown-clock").html(text);
    if (remainingHours) {
        text = remainingHours + hourLabel + remainingMinutes + minuteLabel;
        countdown.find(".countdown-clock").html(text);
    }

    if (remainingDays) {
        text = remainingDays + dayLabel + remainingHours + hourLabel + remainingMinutes + minuteLabel;
        countdown.find(".countdown-clock").html(text);
    }
}