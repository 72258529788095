import * as React from "react";
import {Component} from "react";
import Select from "react-select";
import {inject, observer} from "mobx-react";
import {SearchEngineStore} from "../../SearchEngineStore";
// @ts-ignore
import {observable} from "mobx";

import {ISearchItemProps} from "../index";

import {urlParameters} from "orxapi.widgets.framework/dist/tools/formTools";

import {getText} from "../../../bookingEngine/services/configuration.service";
import Icon from "../../../bookingEngine/components/Icon";

/**
 * the option props interface
 */
export interface IOptionProps {
    code: string;
    label: string;
    group?: string;
    disabled?: boolean;
}

/**
 * the option for select
 */
export interface ISelectOption {
    value: string;
    label: string;
    isDisabled?: boolean;
}

/**
 * props interface of select search item
 */
export interface ISelectSearchItemProps extends ISearchItemProps {
    options: IOptionProps[];
    groupLabels?: unknown;
    placeholder?: string;
    noResultText?: string;
    autocomplete?: boolean;
    multi?: boolean;
    disabled: boolean;
    searchEngineStore: SearchEngineStore;
    disableIndicators?: boolean;
    clearable?: boolean;
    hiddenValue?: string;
    checkboxLabel?: string;
    iconHtml?: string;
    keepOpen?: boolean;
    onChange?: (label: string) => void;
    callBack?: (label: string) => void;
    focusOnUpdate?: boolean;
    submitOnSelect?: boolean;
    useNative?: boolean;
    highlightOption?: boolean;
    matchFromStart?: boolean;
}

interface ISelectSearchItemState {
    toSubmit: boolean;
    showCross: boolean;
    options: any;
}

/**
 * search input with a select and an optional links panel
 */
@inject("searchEngineStore")
@observer
export default class SelectSearchItem extends Component<ISelectSearchItemProps, ISelectSearchItemState> {

    @observable private check: boolean;
    private ref: Select;
    private wrapper: HTMLDivElement;
    private hasLabel: boolean;
    private inputRef;

    constructor(props: Readonly<ISelectSearchItemProps>) {
        super(props);
        this.check = false;
        this.inputRef= React.createRef();
        if (this.props.options && this.props.options.length > 0 && !props.clearable && !props.placeholder && !props.searchEngineStore.values[props.name]) {
            props.searchEngineStore.selectValue(this.props.name, [this.props.options[0].code]);
        }
        this.hasLabel = false;
        this.handleUpdateSelect = this.handleUpdateSelect.bind(this);
    }

    /**
     * component did mount
     */
    public componentDidMount(): void {
        this.setState({toSubmit: false});
        this.setState({showCross: false});
        this.setState({options: this.props.options || []});
        if (this.props.callBack) {
            const selectedOptions = this.findSelectedValue(this.props.searchEngineStore.values[this.props.name]);
            if (selectedOptions && selectedOptions.length > 0) {
                this.props.callBack(selectedOptions
                    .map((option: ISelectOption) => option.label)
                    .join(","));
            }
        }
    }

    /**
     * handle select receive update event
     */
    private handleUpdateSelect() {
        const selected = this.findSelectedValue(this.props.searchEngineStore.values[this.props.name]);
        if (selected) {
            const newValueLabel = selected.map((o: ISelectOption) => o.label).join(",");
            this.props.onChange(newValueLabel);
        } else {
            this.props.onChange(undefined);
        }
    }

    /**
     * component did update
     */
    public componentDidUpdate(): void {
        if (this.ref && this.props.focusOnUpdate) {
            this.ref.focus();
        }
        if (this.state.toSubmit) {
            this.handleSubmit();
        }
        // trick to tell to panel the item has not label anymore
        if (this.props.onChange && this.hasLabel
            && (!this.props.searchEngineStore.values[this.props.name]
                || this.props.searchEngineStore.values[this.props.name].length <= 0
                || !this.props.searchEngineStore.values[this.props.name][0])) {
            this.props.onChange(undefined);
            this.hasLabel = false;
        }
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        const searchEngineStore = this.props.searchEngineStore;
        const {name, placeholder, autocomplete} = this.props;
        const selectedOptions = this.findSelectedValue(searchEngineStore.values[name]);
        const selectedOption = this.getFirstSelectedValue(selectedOptions);
        if (!selectedOption) {
            if (name === "s_aj") {
                searchEngineStore.setValue(name, "2");
            } else {
                searchEngineStore.setValue(name, "");
            }
        }
        const selectOption = (option: IOptionProps) => {
            searchEngineStore.setValue(name, option.code);
        };

        const handleResearch = () => {
            if (this.inputRef.current.value.length > 0) {
                this.setState({showCross: true});
                const researchValue = this.RegexResearchValue(this.inputRef.current.value);
                if (researchValue.length >= 3) {
                    this.setState({options: this.optionsFilterFunction(researchValue)});
                }
            }
            if (this.inputRef.current.value.length === 0) {
                this.setState({showCross: false});
                this.setState({options: this.props.options || []});
            }
        };

        const clearResearch = () => {
            this.inputRef.current.value = "";
            this.inputRef.current.focus();
            this.setState({options: this.props.options || []});
            this.setState({showCross: false});
        };

        let isDark = false;
        let isDurationIcon = false;
        let isLocationIcon = false;
        if (name === "s_aj" || name === "s_minMan" || name === "s_c.so") {
            isDark = true;
        }
        if (name === "s_aj" || name === "s_minMan") {
            isDurationIcon = true;
        }
        if (name === "s_dpci" || name === "s_c.de") {
            isLocationIcon = true;
        }

        return (
            <div className={`elem-custom-select panel-right ${isDark ? " dark-style" : ""}`} onClick={autocomplete ? clearResearch :  null}>
                <div className="custom-select-mask">
                    <div className="custom-select-input">
                        <div className="custom-select-input-txt">
                            {isLocationIcon && <Icon icon={"l"} addClass={"location-icon"}/>}
                            {isDurationIcon && <Icon icon={"T"} addClass={"duration-icon"}/>}
                            <div className="label-style">{(selectedOption && selectedOption.label) || getText("search.loading.label")}</div>
                            {selectedOption && selectedOption.value !== "" && <input name={name} type="hidden" value={selectedOption.value}/>}
                        </div>
                        <div className="custom-select-input-button">{getText("search.select.change")}</div>
                    </div>
                </div>
                <div className="custom-select-panel d-none">

                    <div className="title-block">
                        <div className="label-block">
                            <div className="label">{placeholder}</div>
                            <div className="legend">{getText("search.select.label")}</div>
                        </div>
                        <div className="icon-wrap btn-close-custom-select">
                            <Icon icon={"x"}/>
                        </div>
                    </div>
                    <div className="custom-select-panel-content">
                        {autocomplete &&
                            <div className={"travel-input-div"}>
                                <i className={"elem-ff-icon travel-input-icon left"} data-icon={"R"} role="img"/>
                                <input id={`travelInput${name}`} className={"travel-input"} type="text" onChange={handleResearch} ref={this.inputRef}/>
                                {this.state?.showCross &&
                                    <i className={"elem-ff-icon travel-input-icon right"} data-icon={"x"} role="img" onClick={clearResearch}/>
                                }
                            </div>
                        }
                        {!this.state?.options &&
                                <div className="panel-content-item placeholder-style">{getText("search.loading.label")}</div>
                        }
                        {this.state?.options.map((option: IOptionProps, index: number) =>
                            <div
                                key={option.code + "_" + index}
                                onClick={() => {
                                    const linkPrefix = "link:";
                                    if (option.code.startsWith(linkPrefix)) {
                                        const newSerp = option.code.slice(linkPrefix.length);
                                        window.location.href = window.location.href.replace("serp", newSerp);
                                    }
                                    selectOption(option);
                                }}
                                className={`panel-content-item ${selectedOption && option.code === selectedOption.value ? "selected" : ""}`}
                            >
                                {option.label}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    public RegexResearchValue(researchValue: string)  {
        return researchValue
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[0-9]/g, "")
            .replace(/\s/g, "")
            .replace(/[.,\/<>#!?§$£¤*%µ\^&\*;:{}=\-_"'`~()]/g,"");
    }

    public optionsFilterFunction (researchValue: string) {
        return this.props.options.filter(option => this.RegexResearchValue(option.label).includes(researchValue));
    }

    /**
     * Handles submit
     */
    private handleSubmit() {
        if (this.props.submitOnSelect) {
            $(this.wrapper).trigger("search-engine:submit");
        }
    }

    /**
     * find the selected value
     * @param selected
     */
    private findSelectedValue(selected: string[]): ISelectOption[] {
        if (selected && selected.length !== 0 && this.props.options && this.props.options.length !== 0) {
            let selectedOptions = this.props.options
                .filter(({code}: IOptionProps) => selected.indexOf(code) !== -1)
                .map(({code, label}: IOptionProps) => ({value: code, label}));
            if (selectedOptions.length === 0) {
                selectedOptions = this.props.options
                    .filter(({code}: IOptionProps) => selected.join(urlParameters.or) === code)
                    .map(({code, label}: IOptionProps) => ({value: code, label}));
            }
            return selectedOptions;
        }
    }

    /**
     * getFirstSelectedValue
     * @param selectedOptions
     * @private
     */
    private getFirstSelectedValue(selectedOptions: ISelectOption[]): ISelectOption {
        let selectedOption;
        if (selectedOptions && selectedOptions.length > 0) {
            selectedOption = selectedOptions[0];
        }
        return selectedOption;
    }
}
