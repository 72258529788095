import * as React from "react";
import {Component} from "react";
import DayPicker, {DayModifiers} from "react-day-picker";
import DateSearchItemStore, {dateSearchItemConfiguration} from "./DateSearchItemStore";
import {inject, observer} from "mobx-react";
import MomentLocaleUtils from "react-day-picker/moment";
import MonthHead from "./MonthHead";
import {SearchEngineStore} from "../../SearchEngineStore";
import {ISearchItemProps} from "../index";
import * as moment from "moment";
import Icon from "../../../bookingEngine/components/Icon";
import "moment/locale/fr";

export interface IDateSearchItemProps extends ISearchItemProps {
    label: string;
    dayName: string;
    monthName: string;
    minDepDate: string;
    maxDepDate: string;
    validLabel?: string;
    submitOnValid?: boolean;
    placeholder?: string;
    monthToShow?: number;
    startAtFromToday?: number;
    triggerDiv?: boolean;
    triggerDivLabel?: string;
    containerTitle?: string;
    anyDateLabel?: string;
    anyDateButtonLabel?: string;
    footerControls?: boolean;
    visibleDateFormat?: string;
    locale?: string;
    allMonthLabel?: string;
    allMonthSingle?: boolean;
    allMonthNoFlex?: boolean;
    searchEngineStore: SearchEngineStore;
    iconHtml?: string;
    inputForStartLimit?: string;
    inputForEndLimit?: string;
    mobileCloseLabel?: string;
    toTargetName?: string;
    fromTargetName?: string;
    keepOnClean?: boolean;
}

interface IDateSearchItemState {
    open: boolean;
}

/**
 * the search item by date
 */
@inject("searchEngineStore")
@observer
export default class DateSearchItem extends Component<IDateSearchItemProps, IDateSearchItemState> {
    private readonly store: DateSearchItemStore;
    private dateSearchItemRef: HTMLDivElement;
    private readonly locale: string;
    private oldDate: Date;
    private readonly itemText: any;
    private wrapperRef: any;
    public constructor(props: Readonly<IDateSearchItemProps>) {
        super(props);
        this.store = props.searchEngineStore.getDateStoreOrCreate(DateSearchItem.getCode(this.props, this.props.searchEngineStore));
        const dayValue = props.searchEngineStore.values[props.dayName];
        const monthYearValue = props.searchEngineStore.values[props.monthName];
        const fullDateValue = props.searchEngineStore.values[props.name];
        this.itemText = {};
        this.state = {open: false};
        this.wrapperRef = React.createRef();
        if (props.name && fullDateValue) {
            this.store.selectDate(moment(fullDateValue, "DD/MM/YYYY").toDate());
        } else if (!props.name && dayValue && monthYearValue) {
            const departureDate = moment(dayValue[0] + "/" + monthYearValue[0], "DD/MM/YYYY").toDate();
            this.store.selectDate(departureDate);
        }
        const minDep = props.searchEngineStore.values[props.minDepDate];
        const maxDep = props.searchEngineStore.values[props.maxDepDate];
        if (minDep && maxDep) {
            const min = moment(minDep, "YYYY-MM-DD");
            const max = moment(maxDep, "YYYY-MM-DD");
            const adjust = this.store.adjustValue;
            if (adjust) {
                min.add(adjust, "day");
                max.add(-adjust, "day");
            }
            this.store.addMonth(min.format(dateSearchItemConfiguration.monthYearPattern));
            this.store.addMonth(max.format(dateSearchItemConfiguration.monthYearPattern));

        }
        this.handleCloseCalendarContainer = this.handleCloseCalendarContainer.bind(this);
        this.locale = this.props.locale || "fr";
        moment.locale(this.locale);
        this.cleanSelection = this.cleanSelection.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.setClose = this.setClose.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    /**
     * create a code for this date search item
     */
    public static getCode(props: IDateSearchItemProps, searchEngineStore: SearchEngineStore) {
        let code = "";
        if (props.name) {
            code += props.name;
        }
        if (props.dayName && props.monthName) {
            code += props.dayName + "_" + props.monthName;
        }
        return this.buildDateStoreCode(searchEngineStore, code);
    }

    /**
     * create a code for this date search item
     */
    public static buildDateStoreCode(searchEngineStore: SearchEngineStore, name: string): string {
        return searchEngineStore.currentSearchEngine.code + "_" + name;
    }

    /**
     * component did mount
     */
    public componentDidMount() {
        // deprecated, use date-search-item:close
        this.dateSearchItemRef.addEventListener("dateSearchItem:close", this.handleCloseCalendarContainer);
        this.dateSearchItemRef.addEventListener("date-search-item:close", this.handleCloseCalendarContainer);
        this.dateSearchItemRef.addEventListener("date-search-item:clean", this.cleanSelection);
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * component will unmount
     */
    public componentWillUnmount() {
        // deprecated, use date-search-item:close
        this.dateSearchItemRef.removeEventListener("dateSearchItem:close", this.handleCloseCalendarContainer);
        this.dateSearchItemRef.removeEventListener("date-search-item:close", this.handleCloseCalendarContainer);
        this.dateSearchItemRef.removeEventListener("date-search-item:clean", this.cleanSelection);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * component did update
     */
    public componentDidUpdate(prevProps: Readonly<IDateSearchItemProps>, prevState: Readonly<IDateSearchItemState>) {
        // TODO do the same for month
        if (this.oldDate !== this.store.activeDepartureDate) {
            $(this.dateSearchItemRef).trigger("date-search-item:change", this.store.activeDepartureDate);
        }
        this.oldDate = this.store.activeDepartureDate;

        if (prevState.open !== this.state.open) {
            $(this.dateSearchItemRef).trigger("search-item:" + (this.state.open ? "opening" : "closing"));
        }
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        return this.renderItem();
    }

    /**
     * Renders item
     */
    private renderItem(): JSX.Element {
        const {
            label, name, footerControls, dayName, monthName, minDepDate, maxDepDate
        } = this.props;
        const activeDepartureDate = this.store.activeDepartureDate;
        const activeArrivalDate = this.store.activeArrivalDate;
        const dateToStart = this.getDateToStart();
        const dateToEnd = this.getDateToEnd();
        const momentActiveDepartureDate = moment(activeDepartureDate);
        return (
            <div className="elem-custom-select panel-right  dark-style">
                <div ref={(elem: HTMLDivElement) => this.dateSearchItemRef = elem} className={"date-search-item " + (this.state.open ? "open" : "")}>
                    {this.getTriggerElement()}
                    {activeDepartureDate &&
                    <>
                        {!name &&
                        <>
                            <input type={"hidden"} name={dayName} value={momentActiveDepartureDate.format("DD")}/>
                            <input type={"hidden"} name={monthName} value={momentActiveDepartureDate.format("MM/YYYY")}/>
                        </>
                        }
                    </>
                    }
                    {minDepDate && maxDepDate && this.store.allMonthsMinDepartureDate && this.store.allMonthsMaxDepartureDate &&
                    <>
                        <input
                            type={"hidden"}
                            name={minDepDate}
                            value={moment(this.store.allMonthsMinDepartureDate).format("YYYY-MM-DD")}
                        />
                        <input
                            type={"hidden"}
                            name={maxDepDate}
                            value={moment(this.store.allMonthsMaxDepartureDate).format("YYYY-MM-DD")}
                        />
                    </>
                    }
                    {this.state.open &&
                    <div className="datepicker-container"  ref={this.wrapperRef}>
                        <div className="cpt-popover">
                            <div className="title-mobile d-md-none">{label}
                                <span className="btn-close-datepicker-mobile" onClick={this.setClose}>{this.props.mobileCloseLabel || "X"}</span>
                            </div>
                            {this.props.containerTitle &&
                          <div className="panel-container-title">{this.props.containerTitle}</div>
                            }
                            <div className="scrollbar">
                                {!footerControls && this.getAnyDateElement()}
                                <DayPicker
                                    numberOfMonths={2}
                                    fromMonth={dateToStart}
                                    toMonth={dateToEnd}
                                    initialMonth={activeDepartureDate ||
                              (this.store.months[0] ? moment(this.store.months[0], dateSearchItemConfiguration.monthYearPattern).toDate() : dateToStart)}
                                    disabledDays={{before: dateToStart, after: dateToEnd}}
                                    selectedDays={this.getSelectedDays()}
                                    onDayClick={(date: Date, modifiers: DayModifiers) => {
                                        return this.handleDayClick(date, modifiers);
                                    }}
                                    modifiers={{included: this.store.includedDates, start: activeDepartureDate, end: activeArrivalDate}}
                                    localeUtils={MomentLocaleUtils}
                                    locale={this.locale}
                                    captionElement={({date}: { date: Date }) => (
                                        <MonthHead
                                            store={this.store}
                                            date={date}
                                            allMonthLabel={this.props.allMonthLabel}
                                            allMonthSingle={this.props.allMonthSingle}
                                            allMonthNoFlex={this.props.allMonthNoFlex}
                                            firstMonth={dateToStart}
                                            handleCloseCalendarContainer={this.handleCloseCalendarContainer}
                                        />
                                    )}
                                />
                                {this.getFooterControls()}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }

    /**
     * Alert if clicked on outside of element
     */
    private handleClickOutside(event: any) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.handleCloseCalendarContainer();
        }
    }

    /**
     * Renders footer
     */
    private getFooterControls() {
        if (this.props.footerControls && this.props.validLabel) {
            return (
                <div className="footer-controls-block">
                    <div className="button-wrap">
                        <span className="elem-button--border-default btn-panel" onClick={() => {
                            this.store.setEmptyLabel(this.props.anyDateLabel || this.props.placeholder || "");
                            this.store.cleanMonths();
                            this.store.selectDate(null);
                            this.store.resetAdjustDate();
                        }}>
                            {this.props.anyDateButtonLabel || this.props.anyDateLabel}
                        </span>
                        <button className="elem-button--primary btn-panel" onClick={() => this.handleValidation()}>
                            {this.props.validLabel}
                        </button>
                    </div>
                </div>
            );
        }
    }

    /**
     * Handles validation click
     */
    private handleValidation() {
        this.setClose();
        if (this.props.submitOnValid) {
            $(this.dateSearchItemRef).trigger("search-engine:submit");
        }
    }

    /**
     * clean all date selected
     */
    private cleanSelection() {
        this.store.setEmptyLabel(this.props.anyDateLabel || "");
        this.store.cleanMonths();
        if (!this.props.keepOnClean) {
            this.setClose();
        }
        this.store.selectDate(null);
    }

    /**
     * get the date to start the selectable dates
     */
    private getDateToStart(): Date {
        const {startAtFromToday, inputForStartLimit} = this.props;
        if (inputForStartLimit) {
            const inputItem = $(this.dateSearchItemRef)
                .parents("form")
                .find("input[name='" + inputForStartLimit + "']");
            if (inputItem.length > 0) {
                const limit = inputItem.val();
                if (limit) {
                    return moment(limit, "DD/MM/YYYY").add(1, "d").toDate();
                }
            }
        }
        if (startAtFromToday) {
            const dateToStart = new Date();
            dateToStart.setDate(dateToStart.getDate() + startAtFromToday);
            return dateToStart;
        } else {
            return new Date();
        }
    }

    /**
     * get the date to end the selectable dates
     */
    private getDateToEnd(): Date {
        const {inputForEndLimit} = this.props;
        if (inputForEndLimit) {
            const inputItem = $(this.dateSearchItemRef)
                .parents("form")
                .find("input[name='" + inputForEndLimit + "']");
            if (inputItem.length > 0) {
                const limit = inputItem.val();
                if (limit) {
                    return moment(limit, "DD/MM/YYYY").add(-1, "d").toDate();
                }
            }
        }
    }

    /**
     * get the selected dates
     */
    private getSelectedDays() {
        return this.store.activeDepartureDate || {
            from: this.store.allMonthsMinDepartureDate,
            to: this.store.allMonthsMaxDepartureDate
        };
    }

    /**
     * close calendar container
     */
    private handleCloseCalendarContainer() {
        if (this.state.open) {
            this.setClose();
        }
    }

    /**
     * Get any date element
     */
    private getAnyDateElement(): JSX.Element {
        if (this.props.anyDateLabel) {
            return (
                <div className="default-block">
                    <div className="d-flex">
                        <div className="item" onClick={this.cleanSelection}>
                            {this.props.anyDateLabel}
                        </div>
                    </div>
                </div>
            );
        }
    }

    /**
     * Get trigger element
     */
    private getTriggerElement(): JSX.Element {
        return (
            <div className="custom-select-mask">
                <div className="custom-select-date" onClick={this.toggleOpen} onBlur={this.handleCloseCalendarContainer}>
                    <div className="custom-select-input-txt">
                        <Icon icon={"C"} addClass={"calendar-icon"}/>
                        <div className="label-style">{this.getTriggerElementValue()}</div>
                    </div>
                    <div className="custom-select-input-button">Modifier</div>
                </div>
            </div>
        );
    }

    /**
     * Returns the value of the trigger element
     */
    private getTriggerElementValue(): string {
        const visibleDateFormat = "dddd DD MMMM YYYY";
        if (this.store.activeDepartureDate) {
            return moment(this.store.activeDepartureDate).format(visibleDateFormat);
        } else if (this.props.allMonthSingle && this.store.visibleMonthValue) {
            return this.store.visibleMonthValue;
        } else if (this.store.visibleMonthsValue) {
            return this.store.visibleMonthsValue;
        }

        return this.props.anyDateLabel;
    }

    /**
     * open datepicker
     */
    private setOpen() {
        this.setState({open: true});
    }

    /**
     * close datepicker
     */
    private setClose() {
        this.setState({open: false});
    }

    /**
     * toggle datepicker
     */
    private toggleOpen() {
        this.setState({open: !this.state.open});
    }

    /**
     * handle day click
     */
    private handleDayClick(date: Date, modifiers: DayModifiers) {
        if (!modifiers.disabled) {
            this.setClose();
            return this.store.selectDate(date);
        }
    }
}
