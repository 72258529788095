import * as $$ from "jquery-selector-cache";
import {isDesktopBreakpoint} from "../../_core/scripts/specific/layout/breakpoints";

/**
 * Initialization Accordion ORXAPI
 * TODO transfer these actions in React, to get rid of jQuery
 */
function initElemCustomSelect() {
    // Exit when component don't exist
    if (!$$(".elem-custom-select").length) {
        return;
    }

    // Events
    $$(".elem-custom-select").on("click.customSelect", ".custom-select-input", handleClickCustomSelect);
    $$(".elem-custom-select").on("click.btnCloseCustomSelect", ".btn-close-custom-select", handleClosePanel);
    $$(".elem-custom-select").on("click.bookingCalendarItem", ".booking-calendar-item", handleClosePanel);
    $$(".elem-custom-select").on("click.panelContentItem", ".panel-content-item", handleClosePanel);
    $$(".elem-custom-select").on("click.bookingTravelersElementButton", ".booking-travelers-content .elem-button", handleClosePanel);
    $$(".overlay-control").on("click.bookingOverlay", ".booking-overlay", handleCloseOverlay);
}

/**
 * Handler of the customSelect click for open panel
 * @param evt
 */
export function handleClickCustomSelect(evt) {
    $$("body").toggleClass("body-no-scroll", !isDesktopBreakpoint());

    const delegateTarget = $(evt.delegateTarget);
    delegateTarget.parent().addClass("active");

    $$(".booking-overlay").removeClass("fade-out-animate");
    $$(".booking-overlay").removeClass("d-none");
    $$(".booking-overlay").addClass("fade-in-animate");

    const currentTarget = $(evt.currentTarget);
    const panel = currentTarget.parent(".custom-select-mask").next(".custom-select-panel");
    panel.removeClass("fade-out-animate");
    panel.removeClass("d-none");
    panel.addClass("fade-in-top");

    const windowHeight = $(window).outerHeight();
    const heightTitleBlock = 53;
    const calcPanelContent = ((windowHeight - heightTitleBlock) - 14);
    const customSelectPanel = currentTarget.parent(".custom-select-mask").next(".custom-select-panel");
    const customSelectPanelContent = customSelectPanel.find(".custom-select-panel-content");
    customSelectPanelContent.css("height", calcPanelContent);

    // Scroll to selected item
    const selected = customSelectPanelContent.find(".selected");
    if (selected.length) {
        const monthTitle = customSelectPanel.find(".month-title");

        customSelectPanelContent.animate({
            scrollTop: selected.offset().top - customSelectPanelContent.offset().top + customSelectPanelContent.scrollTop() - monthTitle.outerHeight(),
            scrollLeft: 0
        }, 300);
    }
}

/**
 * Handler of the panel close
 */
export function handleClosePanel() {
    $(".booking-overlay").trigger("click.bookingOverlay");
}

/**
 * handler of the overlay and the panel close
 * @param evt
 */
function handleCloseOverlay(evt: JQuery.ClickEvent) {
    $$("body").removeClass("body-no-scroll");
    $$(".js-switch-active").removeClass("active");

    const currentTarget = $(evt.currentTarget);
    currentTarget.removeClass("fade-in-animate");
    currentTarget.addClass("fade-out-animate");
    currentTarget.animate({top: 0}, 190, () => {
        currentTarget.addClass("d-none");
    });

    $$(".custom-select-panel").removeClass("fade-in-top");
    $$(".custom-select-panel").addClass("fade-out-animate");
    $$(".custom-select-panel").addClass("d-none");
}

export {
    initElemCustomSelect,
};
