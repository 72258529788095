import * as $$ from "jquery-selector-cache";
import * as fe from "../../_core/scripts/specific/filterEngine/filterEngine";
import {isDesktopBreakpoint} from "../../_core/scripts/specific/layout/breakpoints";
/**
 * Initialize the component
 */
export function initFilters() {
    // Exit when component don't exist
    if (!$$(".cpt-filter-panel").length) {
        return;
    }
    const filterEngine = $("#filterEngine");
    filterEngine.data("autoSubmit", isDesktopBreakpoint());
    fe.initFilterEngine(filterEngine[0], filterEngine.data("dataUrl"));
}
