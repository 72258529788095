import * as React from "react";
import {Component, SyntheticEvent} from "react";
import {inject, observer} from "mobx-react";
import SubmitButton from "orxapi.widgets.framework/dist/components/button/SubmitButton";
import {getUrlVar} from "orxapi.tools.url";

import {ISearchItem} from "../SearchItem";
import SearchItemContainer from "../SearchItemContainer";
import {addHideQueryInputs, ISearchType, submitForm} from "orxapi.widgets.framework/dist/tools/formTools";
import {SearchEngineStore} from "../SearchEngineStore";
import {urlParametersConfig} from "../../config/form";
import {sendSearchRequestTracking} from "../../tools/Tracking";

/**
 * interface to describe a search engine
 */
export interface ISearchEngine {
    title: string;
    code: string;
    searchItems?: ISearchItem[];
    baseQuery?: string;
    moreItems?: ISearchItem[];
    defaultValues?: IDefaultValue[];
    searchTypes?: ISearchType[];
    subSearchEngines?: ISearchEngine[];
    iconUrl?: string;
    submitUrl?: string;
    requiredParameters?: Array<{ name: string; target: string }>;
}

/**
 * the search engine layouts props
 */
export interface ISearchEngineLayoutProps {
    submitUrl?: string;
    submitLabel?: string;
    defaultSubmit?: boolean;
    searchEngineStore?: SearchEngineStore;
    itemsColClass?: string;
    buttonColClass?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISearchEngineLayoutState {

}

/**
 * interface described a default value
 */
interface IDefaultValue {
    code?: string;
    value?: string;
}

/**
 * the main search engine layout
 */
@inject("searchEngineStore")
@observer
export default class SearchEngineLastminuteLayout extends Component<ISearchEngineLayoutProps, ISearchEngineLayoutState> {

    constructor(props: ISearchEngineLayoutProps) {
        super(props);
    }

    /**
     * the render
     */
    public render(): JSX.Element {
        const useDefaultSubmit = this.props.defaultSubmit === undefined ? true : this.props.defaultSubmit;
        const currentSearchEngine = this.props.searchEngineStore.currentSearchEngine;
        return (
            <section className="cpt-search-engine overlay-control d-none d-lg-block">
                <>
                    {currentSearchEngine.title && <h2 className="h2-style"
                        dangerouslySetInnerHTML={{__html: currentSearchEngine.title}}/>}
                    <form action={this.props.submitUrl}
                        {...(useDefaultSubmit ? {onSubmit: this.tryToSubmitForm(currentSearchEngine)} : {})}
                    >
                        {this.addBaseQueryInputs()}
                        <div className="booking-overlay d-none"/>
                        <div className="search-panel-content">
                            {currentSearchEngine.searchItems.map((item: ISearchItem) =>
                                <SearchItemContainer
                                    key={"searchItemContainer_" + item.type + "_" + item.code + "_" + item.data.name}
                                    {...item}
                                />)}
                        </div>
                        <div className="button-wrap">
                            <SubmitButton label={this.props.submitLabel}
                                buttonClass={"elem-button elem-button--default "}/>
                        </div>
                        {this.addQueryInput()}
                    </form>
                </>
            </section>
        );
    }

    /**
     * add input from the base query if useAdv=true
     */
    protected addQueryInput() {
        const useAdvValue = getUrlVar()[urlParametersConfig.useAdv];
        const toValue = getUrlVar()[urlParametersConfig.to];
        return (
            <>
                {useAdvValue === "true" &&  <input name={urlParametersConfig.useAdv} type="hidden" value={useAdvValue}/>}
                {useAdvValue === "true" &&  <input name={urlParametersConfig.to} type="hidden" value={toValue}/>}
                <input name={urlParametersConfig.searchSubmit} type="hidden" value={"true"}/>
            </>
        );
    }

    /**
     * add input from the base query of selected search engine
     */
    protected addBaseQueryInputs(): JSX.Element {
        const currentSearchEngine = this.props.searchEngineStore.currentSearchEngine;
        return (
            <>
                {addHideQueryInputs(currentSearchEngine.baseQuery)}
            </>
        );
    }

    /**
     * submit form if it's possible according to required parameters
     * @param searchEngine
     * @param searchTypes
     */
    protected tryToSubmitForm(searchEngine: ISearchEngine, searchTypes?: ISearchType[]) {
        return (evt: SyntheticEvent) => {
            const formData = new FormData(evt.target as HTMLFormElement);
            sendSearchRequestTracking(formData);
            submitForm(evt, searchTypes || []);
        };
    }
}
