import * as React from "react";
import {Component} from "react";
import {getSearchItemComponent, ISearchItem} from "../SearchItem";

/**
 * the search item container to contain a search item
 * @class SearchItemContainer
 */
export default class SearchItemContainer extends Component<ISearchItem, any> {

    /**
     * the render
     */
    public render(): JSX.Element {
        const {code, label, type, data, disabled, addClass, onChange} = this.props;
        return (
            <div className={"search-panel-item js-switch-active" + ((code ? " " + code : "") || "") + (disabled ? " disabled" : "") + " " + (addClass || "")}>
                {label && <div className="label-select">{label}</div>}
                {getSearchItemComponent(type, data, label, disabled, onChange)}
            </div>
        );
    }
}
