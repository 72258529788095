import * as React from "react";
import {Component} from "react";
import MoreLessNumberRow from "../Pax/MoreLessNumberRow";
import {inject, observer} from "mobx-react";
import {SearchEngineStore} from "../../SearchEngineStore";

import {ISearchItemProps} from "../index";
import Icon from "../../../bookingEngine/components/Icon";
import {getText} from "../../../bookingEngine/services/configuration.service";

interface IPassengerItem {
    name: string;
    initialValue: number;
    maxValue?: number;
    minValue?: number;
}

export interface IPassengerSearchItemProps extends ISearchItemProps {
    searchEngineStore: SearchEngineStore;
    paxItems: IPassengerItem[];
    maxTotalValue: number;
    totalNumberLabel: string;
    mention: string;
    placeholder: string;
}

interface IPassengerSearchItemState {
    open: boolean;
}

/**
 * the passenger search item
 */
@inject("searchEngineStore")
@observer
export default class PassengerSearchItem extends Component<IPassengerSearchItemProps, IPassengerSearchItemState> {

    /**
     * constructor
     * @param props
     */
    constructor(props: Readonly<IPassengerSearchItemProps>) {
        super(props);
        const searchEngineStore = props.searchEngineStore;
        this.props.paxItems.forEach((item: IPassengerItem) => {
            const name = item.name;
            const storedValues = searchEngineStore.values[name];
            if (!storedValues || storedValues.length <= 0) {
                searchEngineStore.setValue(name, String(item.initialValue || item.minValue || 0));
            }
        });
    }

    /**
     * Renders component
     */
    public render(): JSX.Element {
        return this.props.manageOwnLabel ? this.renderItemWithLabel() : this.renderItem();
    }

    /**
     *  Renders item with its label
     */
    private renderItemWithLabel(): JSX.Element {
        return (
            <>
                <div className="item-label-se">{this.props.label}</div>
                <div className="item-content-se">{this.renderItem()}</div>
            </>
        );
    }

    /**
     * Renders item
     */
    private renderItem(): JSX.Element {
        const {name, placeholder, mention, paxItems, maxTotalValue} = this.props;
        const totalNumber = this.getTotalNumber();
        const inputValue = totalNumber > 0 ? `${totalNumber} ${getText("search.passenger.label")}${(totalNumber > 1) ? "s" : ""}` : mention;
        return (
            <div className={"elem-custom-select panel-right dark-style"}>
                <div className="custom-select-mask">
                    <div className="custom-select-input">
                        <div className="custom-select-input-txt">
                            <Icon icon={"M"} addClass={"duration-icon"}/>
                            <div className="label-style">{inputValue || getText("search.loading.label")}</div>
                            {totalNumber > 0 &&
                                <input type={"hidden"} name={name} value={totalNumber}/>
                            }
                        </div>
                        <div className="custom-select-input-button">{getText("search.select.change")}</div>
                    </div>
                </div>
                <div className="custom-select-panel d-none">

                    <div className="title-block">
                        <div className="label-block">
                            <div className="label">{placeholder}</div>
                            <div className="legend" dangerouslySetInnerHTML={{__html: getText("search.passenger.max.label").replace("{max}", (maxTotalValue || 9).toString())}}/>
                        </div>
                        <div className="icon-wrap btn-close-custom-select">
                            <Icon icon={"x"}/>
                        </div>
                    </div>
                    <div className="custom-select-panel-content passenger">
                        {paxItems.map((paxItem: IPassengerItem) =>
                            <MoreLessNumberRow
                                key={"paxItem_" + paxItem.name}
                                searchEngineStore={this.props.searchEngineStore}
                                {...paxItem}
                                title={getText(`search.passenger.${paxItem.name}`)}
                                classPrefix={"passenger-number"}
                                moreThanMaxTotal={maxTotalValue && maxTotalValue !== 0 && totalNumber >= maxTotalValue}
                                legend={getText(`search.passenger.${paxItem.name}.legend`)}
                                totalNumber={totalNumber}
                                withAgeSelection={paxItem.name.includes("child")}
                            />
                        )}
                        <div className="btn-wrap">
                            <span className="elem-button elem-button--default btn-close-custom-select">{getText("search.passenger.submit.label")}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * getTotalNumber
     * @private
     */
    private getTotalNumber(): number {
        return this.props.paxItems
            .slice(0)
            .map((paxItem: IPassengerItem) => {
                const value = Number(this.props.searchEngineStore.values[paxItem.name][0]);
                return value;
            })
            .filter((value: number) => value)
            .reduce((previousValue: number, currentValue: number) => previousValue + currentValue, 0);
    }
}
