import * as $$ from "jquery-selector-cache";
import {sendResultClickedTracking} from "../../_core/scripts/specific/tools/Tracking";

/**
 * Initialize the module
 */
export function initBtnVideoPopin() {
    // Exit when component don't exist
    if (!$$(".video-button").length) {
        return;
    }

    // Exit when component don't exist
    if (!$$(".cpt-popin-video").length) {
        return;
    }
    $$(".video-button").on("click.toggleLink", (evt: JQuery.ClickEvent) => {
        const currentTarget = $(evt.currentTarget);
        evt.preventDefault();
        $$("body").addClass("body-no-scroll");
        if ($$("body").hasClass("bp-xl") || $$("body").hasClass("bp-lg")) {
            $$("body").removeClass("body-no-scroll");
        }
        currentTarget.addClass("active");
        $$(".btn-close-popin").removeClass("fade-out-animate").addClass("fade-out-up-animate").next().removeClass("fade-out-animate").addClass("fade-in-animate");
        $$(".popin-video-content").removeClass("d-none").children(".overlay-popin").removeClass("fade-out-animate").addClass("fade-in-animate");
        $$(".btn-close-popin").on("click", (evtPopin: JQuery.ClickEvent) => {
            evtPopin.preventDefault();
            if (player === undefined || !player || null) {
                console.log("Player could not be found.");
            } else {
                player.stopVideo();
            }
        });
    });
}

export function initFlashSaleHover() {
    const flashSales = $(".flash-sale");

    flashSales.each((index: number, flashSale: HTMLElement) => {
        $$(flashSale).click(
            function(e) {
                e.preventDefault();
                e.stopPropagation();
            }
        );
        $(flashSale).hover(
            function() {
                $(this).find(".added-values-tooltip").removeClass("d-none");
            }, function() {
                $(this).find(".added-values-tooltip").addClass("d-none");
            }
        );
    });
}

export function initDuration() {
    $(".details-pdt-item.duration")
        .filter((_, elem)=> $(elem).find(".details-label").length === 0)
        .each((_, elem)=>{
            const durationItem = $(elem);
            const id = durationItem.data("productId");
            $.ajax(`/ajax/serp/products/${id}/durations${window.location.search}`)
                .then((result) => durationItem.append(result));
        });
}

export function resultItemClick() {
    $$(document).on("click", ".cpt-result-product", handleResultItemClick);
}

function handleResultItemClick(evt: JQuery.ClickEvent){
    const currentTarget = $(evt.currentTarget);
    sendResultClickedTracking(currentTarget.data("id"), currentTarget.data("price"));
}