import * as $$ from "jquery-selector-cache";
import {isMobileBreakpoint} from "../../_core/scripts/specific/layout/breakpoints";
import {initSearchEngine} from "../../_core/scripts/specific/searchEngine/searchEngine";
import {initElemCustomSelect} from "../../elements/customSelect/customSelect";

/**
 * Initialize the component
 */
export function initSearchEngineComponent() {
    const searchEngine = $$("#searchEngine");
    const smallCalendar = isMobileBreakpoint();
    const dataUrl = searchEngine.data("jsonUrl");
    initSearchEngine(searchEngine[0], dataUrl + (dataUrl.indexOf("?") !== -1 ? "&" : "?") + "smallCalendar=" + smallCalendar, initElemCustomSelect);
}
