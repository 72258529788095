import {OptionTypeBase, ValueType} from "react-select/src/types";
import {DateTime} from "luxon";
import * as moment from "moment";

interface PaxSelectConfig<T extends OptionTypeBase> {
    readonly options: Array<ValueType<T>>;
}

export interface OptionType {
    readonly value: string;
    readonly label: string;
}

export interface PaxSelectType {
    readonly adult: PaxSelectConfig<OptionType>;
    readonly children: PaxSelectConfig<OptionType>;
}

/**
 * Configure the pax select options
 */
const paxSelectConfig: PaxSelectType = {
    adult: {
        options: [
            {"value": "1", "label": "1"},
            {"value": "2", "label": "2"},
            {"value": "3", "label": "3"},
            {"value": "4", "label": "4"},
            {"value": "5", "label": "5"},
            {"value": "6", "label": "6"},
            {"value": "7", "label": "7"},
            {"value": "8", "label": "8"},
            {"value": "9", "label": "9"}
        ]
    },
    children: {
        options: [
            {"value": "0", "label": "0"},
            {"value": "1", "label": "1"},
            {"value": "2", "label": "2"},
            {"value": "3", "label": "3"},
            {"value": "4", "label": "4"},
            {"value": "5", "label": "5"},
            {"value": "6", "label": "6"},
            {"value": "7", "label": "7"},
            {"value": "8", "label": "8"}
        ]
    }
};

const CHILD_MAX_AGE = 17;

const getYears = (): OptionType[] => {
    const years: OptionType[] = [];
    const today = DateTime.local();
    for (let step = 0; step <= CHILD_MAX_AGE; step++) {
        const year = today.minus({year: step}).year;
        years.push({value: year, label: year});
    }
    return years;
};

const getMonths = (): OptionType[] => {
    const months: OptionType[] = [];
    for (let step = 1; step <= 12; step++) {
        months.push(createStep(step));
    }
    return months;
};

const getDays = (month: string, year: string): OptionType[] => {
    const days: OptionType[] = [];
    for (let step = 1; step <= moment(`${year}-${month}`, "YYYY-MM").daysInMonth(); step++) {
        days.push(createStep(step));
    }
    return days;
};

const createStep = (step: number): OptionType => {
    const label = step.toString().padStart(2, "0");
    return {value: label, label};
};

export {
    paxSelectConfig,
    getYears,
    getMonths,
    getDays
};
