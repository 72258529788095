/**
 * Return the icon arrow button for Swiper
 * @param addClass The additional class for span element
 * @param addIconClass The additional class for icon
 * @param addDataIcon The additional data for icon
 * @returns {string} The string template for Swiper
 */
export function getSliderIcon(addClass = "", addIconClass = "", addDataIcon = ""): string {
    return `<span class="${addClass}"><i class="elem-ff-icon ${addIconClass}" data-icon="${addDataIcon}"></i></span>`;
}
