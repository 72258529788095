import * as $$ from "jquery-selector-cache";
import {initLazyLoading} from "../../_core/scripts/specific/layout/lazyload";
import {initDuration} from "../resultProduct/resultProduct";
import {initResultItem} from "../productExtra/productExtra";

/**
 * Initialize the module
 */
export function initResultsPanel() {
    // Exit when component don't exist
    if (!$$("#moreProducts").length) {
        return;
    }
    /*$("#moreProducts").on("click", handleMoreProducts);*/
    $$(document).on("click", "#moreProducts", handleMoreProducts);
}

/**
 * handle moreProducts.
 */
function handleMoreProducts(evt: JQuery.TriggeredEvent<HTMLElement>) {
    evt.preventDefault();
    const buttonParent = $("#moreProducts").parent();
    buttonParent.html("loading");
    initToggleLoaderFull();
    const button = $(evt.currentTarget);
    $.ajax(
        button.attr("href"))
        .done((response: any) => {
            buttonParent.remove();
            $(".results-panel-content").append($(response));
            initLazyLoading();
            initToggleLoaderFull();
            initDuration();
            initResultItem();
        });
}

/**
 * Initialize the toggle for loader full
 */
export function initToggleLoaderFull() {
// Exit when component don't exist
    if (!$$(".elem-loader-full").length) {
        return;
    }
    $$(".elem-loader-full").toggleClass("d-none");
}