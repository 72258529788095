import * as $$ from "jquery-selector-cache";

/**
 * Initialize the popin video xcross
 */
export function initOrxPopinVideo() {
    // Exit when component don't exist
    if (!$$(".popin-video-content").length) {
        return;
    }
    $$(".btn-close-popin-video").on("click", () => {
        $$(".btn-close-popin").removeClass("fade-out-up-animate").addClass("fade-out-animate").next().removeClass("fade-in-animate").addClass("fade-out-animate");
        $$(".cpt-orx-popin").find(".overlay-popin").removeClass("fade-in-animate").addClass("fade-out-animate").animate({top: 0}, 190, () => {
            $$("body").removeClass("body-no-scroll");
            $$(".cpt-orx-popin").addClass("d-none");
        });
        $$(".share-block").removeClass("active");
        $$(".cpt-promote-pdt").removeClass("active");
        $$(".video-button").removeClass("active");
    });
}