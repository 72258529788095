import {CheckOptionFilterStore} from "../FilterItem/CheckFilterItem/CheckOptionFilterStore";
import {RangeFilterStore} from "../FilterItem/RangeFilterItem/RangeFilterStore";
import {action, computed, observable} from "mobx";

/**
 * the global filter store
 */
export default class FilterStore {

    private readonly _checkOptionStore: CheckOptionFilterStore;
    private readonly rangeStores: RangeFilterStore[];

    @observable private readonly _values: { [name: string]: string[]; };

    /**
     * constructor
     * @param checkOptionStore the option check store
     */
    constructor(checkOptionStore: CheckOptionFilterStore) {
        this._checkOptionStore = checkOptionStore;
        this.rangeStores = [];
        this._values = {};
    }

    /**
     * add a range store
     * @param store
     */
    public addRangeStore(store: RangeFilterStore) {
        this.rangeStores.push(store);
    }

    /**
     * get the check option store
     */
    get checkOptionStore(): CheckOptionFilterStore {
        return this._checkOptionStore;
    }

    @computed
    public get values(): { [name: string]: string[]; } {
        return this._values;
    }

    /**
     * select a value in array of values
     * @param name
     * @param values
     */
    @action.bound
    public selectValue(name: string, values: string[]) {

        if (this._values[name]) {
            this._values[name].length = 0;
        } else {
            this._values[name] = observable([]);
        }
        this._values[name].push(...values);
    }

    /**
     * reset all item to its initial value
     */
    @action.bound
    public resetAllItem() {
        this.checkOptionStore.resetAll();
        this.rangeStores.forEach((store: RangeFilterStore) => store.reset());
    }
}
