
export interface IBookingEngineQueryParameter {
    [parameter: string]: string;
}

export interface IBookingEngineConfiguration {
    baseUrl: string;
    submitUrl: string;
    queryParameters: IBookingEngineQueryParameter;
}

let configuration: IBookingEngineConfiguration;

/**
 * Retrieve configuration
 */
const getConfiguration = (): IBookingEngineConfiguration => {
    return configuration;
};

/**
 * Set configuration
 */
const setConfiguration = (newConfiguration: IBookingEngineConfiguration) => {
    configuration = newConfiguration;
};

const getLocale = (): string => {
    return (window as any).orxapi.locale.code || "";
};

const getText = (key: string): string => {
    return (window as any).orxapi.locale.i18n[key] || "";
};

export {
    getConfiguration,
    setConfiguration,
    getLocale,
    getText
};