import {UAParser} from "ua-parser-js";

const uaparser = new UAParser();

/**
 * Initialize the User Agent
 */
export function initDevice() {
    if (isMobileDevice()) {
        document.querySelector("body").classList.add("is-mobile");
    }
    if (isTabletDevice()) {
        document.querySelector("body").classList.add("is-tablet");
    }
}

/**
 * Get the actual device
 * @return {IUAParser.IDevice}
 */
function getDevice(): IUAParser.IDevice {
    return uaparser.getDevice();
}

/**
 * Get the OS information
 * @return {IUAParser.IOS}
 */
function getOS(): IUAParser.IOS {
    return uaparser.getOS();
}

/**
 * Check if a device type is mobile
 * @returns {boolean}
 */
export function isMobileDevice(): boolean {
    return getDevice().type === "mobile";
}

/**
 * Check if a device type is tablet
 * @returns {boolean}
 */
export function isTabletDevice(): boolean {
    return getDevice().type === "tablet";
}

/**
 * Check if a iOS
 * @returns {boolean}
 */
export function isIOSDevice(): boolean {
    return getOS().name === "iOS";
}