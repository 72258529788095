// @ts-ignore
import {ISearchEngine} from "../SearchEngineLayout";
import {action, computed, observable} from "mobx";
import DateSearchItemStore from "../SearchItem/DateSearchItem/DateSearchItemStore";

/**
 * The store for search engine
 */
export class SearchEngineStore {
    private readonly searchEngines: ISearchEngine[];
    private defaultSearchEngine: string;
    @observable private selectedSearchEngine: ISearchEngine;
    @observable private showMoreVisible: boolean;
    @observable private readonly _values: { [name: string]: string[]; };
    private readonly dateStore: { [code: string]: DateSearchItemStore; };

    /**
     * the constructor
     * @param searchEngines data array to build the search engine
     */
    constructor(searchEngines: ISearchEngine[]) {
        this.searchEngines = searchEngines;
        this.selectedSearchEngine = searchEngines[0];
        this.showMoreVisible = false;
        this._values = {};
        this.dateStore = {};
    }

    /**
     * get the date store from the code or creat it if not exist
     * @param code
     */
    public getDateStoreOrCreate(code: string): DateSearchItemStore {
        if (!this.dateStore[code]) {
            this.dateStore[code] = new DateSearchItemStore();
        }
        return this.dateStore[code];
    }
    /**
     * return the show more visible state
     */
    @computed
    public get showMoreIsVisible(): boolean {
        return this.showMoreVisible;
    }

    /**
     * get the current search engine
     */
    @computed
    public get currentSearchEngine(): ISearchEngine {
        return this.selectedSearchEngine;
    }

    @computed
    public get allSearchEngines(): ISearchEngine[] {
        return this.searchEngines;
    }

    @computed
    public get values(): { [name: string]: string[]; } {
        return this._values;
    }

    /**
     * select a value in array of values
     * @param name
     * @param values
     */
    @action.bound
    public selectValue(name: string, values: string[]) {
        if (this._values[name]) {
            this._values[name].length = 0;
        } else {
            this._values[name] = observable([]);
        }
        this._values[name].push(...values);
    }

    /**
     * Set a value in array of values
     * @param name
     * @param value
     */
    @action.bound
    public setValue(name: string, value: string) {
        if (this._values[name]) {
            this._values[name].length = 0;
        } else {
            this._values[name] = observable([]);
        }
        this._values[name].push(value);
    }

    /**
     * Add a value in the values map
     * @param name name of the value
     */
    @action.bound
    public addValue(name: string) {
        if (!this._values[name]) {
            this._values[name] = observable([]);
        }
    }

    /**
     * Add a value option in the values map
     * @param valueName name of the value
     * @param option option of the value
     */
    @action.bound
    public addOption(valueName: string, option: string) {
        if (!this._values[valueName] || this._values[valueName].length <= 0 || !this._values[valueName][0]) {
            this._values[valueName] = observable([]);
        }
        this._values[valueName].push(option);
    }

    /**
     * Remove a value option in the values map
     * @param valueName name of the value
     * @param option option of the value
     */
    @action.bound
    public removeOption(valueName: string, option: string) {
        if (this._values[valueName]) {
            this._values[valueName] = this._values[valueName].filter((currentValue: string) => currentValue !== option);
        }
    }

    /**
     * Remove all options of a value in the values map
     * @param valueName name of the value to clear options from
     */
    @action.bound
    public clearOptions(valueName: string) {
        if (this._values[valueName]) {
            this._values[valueName].length = 0;
            this._values[valueName].push(undefined);
        }
    }

    /**
     * select a search engine by code
     */
    @action.bound
    public deselectSearchEngine() {
        this.selectedSearchEngine = undefined;
    }

    /**
     * select a search engine by code
     * @param code
     */
    @action.bound
    public selectSearchEngine(code: string) {
        const filteredSearchEngines = this.searchEngines
            .filter((engine: ISearchEngine) => engine.code === code);
        if (filteredSearchEngines.length !== 0) {
            this.selectedSearchEngine = filteredSearchEngines
                .shift();
        }
    }

    /**
     * check if a search engine exist from code
     * @param code
     */
    public searchEngineExist(code: string): boolean {
        const filteredSearchEngines = this.searchEngines
            .filter((engine: ISearchEngine) => engine.code === code);
        return filteredSearchEngines.length !== 0;
    }

    /**
     * toggle show more visible
     */
    @action.bound
    public toggleShowMoreVisible() {
        this.showMoreVisible = !this.showMoreVisible;
    }

    /**
     * set default code
     * @param code
     */
    public setDefaultSearchEngine(code: string) {
        this.defaultSearchEngine = code;
    }

    /**
     * get default code
     */
    public getDefaultSearchEngine() {
        return this.defaultSearchEngine;
    }

}
