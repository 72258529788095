import "../../_core/scripts/specific/layout/index";
import {initDiaporama} from "../../components/diaporama/diaporama";
import {initProductInformation} from "../../components/tabsProduct/tabsProduct";
import {initOrxAccordion} from "../../components/orxapiwidgetanimation/widget/accordion/accordion";
import {initScrollLink} from "../../components/bookingStickyPrice/bookingStickyPrice";
import {initHeaderNavBar} from "../../components/headerNavBar/headerNavBar";
import {
    initBtnVideoPopin,
    initDuration,
    initFlashSaleHover,
    resultItemClick
} from "../../components/resultProduct/resultProduct";
import {initOrxPopin} from "../../components/popin/popin";
import {initb2bBlock} from "../../components/blockB2b/blockB2b";
import {initSearchEngineComponent} from "../../components/searchEngine/ownSearchEngine";
import {addAdvantageCookie, initResultSort} from "../../components/resultsSort/resultsSort";
import {initGooglePub} from "../../components/googlePanel/googlePanel";
import {initResultsPanel} from "../../components/resultsPanel/resultsPanel";
import {initFilters} from "../../components/filterPanel/filterPanel";
import {initOrxPopinVideo} from "../../components/popinVideo/popinVideo";
import {initResultItem} from "../../components/productExtra/productExtra";
import {initUnbeatablePopIn} from "../../components/unbeatableOffer/unbeatableOffer";
import {setCanalFromUrl} from "../../_core/scripts/specific/tools/Booking";

if (console !== undefined) {
    console.log("initialize serp 😊");
}

initDiaporama(".product-style", "package");
initDiaporama(".thumbnails-style", "packageThumbs");
initSearchEngineComponent();
addAdvantageCookie();
initResultSort();
initProductInformation();
initOrxAccordion();
initScrollLink();
initHeaderNavBar();
initOrxPopin();
initBtnVideoPopin();
initb2bBlock();
initGooglePub();
initResultsPanel();
initOrxPopinVideo();
initFilters();
initResultItem();
initFlashSaleHover();
initUnbeatablePopIn();
resultItemClick();
initDuration();
setCanalFromUrl();