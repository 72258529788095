import * as $$ from "jquery-selector-cache";
import {getUrlVar} from "orxapi.tools.url";
import {urlParametersConfig} from "../../_core/scripts/specific/config/form";
import * as Cookies from "js-cookie";

const sort = $$("#sortOptions");

/**
 * Initialize the component
 */
export function initResultSort() {
    // Exit when component don't exist
    if (!sort.length) {
        return;
    }
    sort.on("change", sort, handleSortClick);
}

/**
 * addAdvantageCookie
 */
export function addAdvantageCookie() {
    const useAdv = getUrlVar()[urlParametersConfig.useAdv];
    if (useAdv && useAdv === "true") {
        Cookies.set("useAdv", useAdv, {expires: 1});
    }
}

/**
 * handle Sort Click
 */
function handleSortClick() {
    const query = sort.val();
    window.location.href = query;
}
