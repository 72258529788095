function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}

function addsCookie(name: string, value: string, expires = "", domain = "") {
    const domainValue = domain != "" ? `domain=${domain}` : "";
    document.cookie = `${name}=${value}${expires}; path=/; ${domainValue}`;
}

export {
    getCookie,
    addsCookie,
};