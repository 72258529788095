import {breakpointsConfig} from "../../_core/scripts/specific/config/responsive";
import {getSliderIcon} from "../../elements/icon/icon";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IDiaporamaConfig<T> {
    default: T;
    package: T;
    cross: T;
    multiple: T;
}

/**
 * Map of Slick options configuration
 * @type {IDiaporamaConfig<JQuerySlickOptions>}
 */
export const diaporamaConfig = {
    // Config default for slider product
    default: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: getSliderIcon("arrow-square arrow-square-slick-prev un-clickable", "", "<"),
        nextArrow: getSliderIcon("arrow-square arrow-square-slick-next un-clickable", "", ">" ),
        responsive: [
            {
                breakpoint: breakpointsConfig.sm.max,
                settings: {
                    arrows: true
                }
            },
            {
                breakpoint: breakpointsConfig.xs.max,
                settings: {
                    arrows: true
                }
            }
        ]
    },
    package: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: getSliderIcon("arrow-square arrow-square-slick-prev un-clickable", "", "<"),
        nextArrow: getSliderIcon("arrow-square arrow-square-slick-next un-clickable", "", ">" ),
        responsive: [
            {
                breakpoint: breakpointsConfig.sm.max,
                settings: {
                    arrows: true
                }
            },
            {
                breakpoint: breakpointsConfig.xs.max,
                settings: {
                    arrows: true
                }
            }
        ],
        asNavFor: ".thumbnails-style"
    },
    packageThumbs: {
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: ".product-style",
        arrows: false,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: breakpointsConfig.md.max,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: breakpointsConfig.lg.max,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: breakpointsConfig.xl.max,
                settings: {
                    slidesToShow: 5,
                }
            }
        ]
    },
    packageSimilar: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: breakpointsConfig.md.max,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: breakpointsConfig.sm.max,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: breakpointsConfig.xs.max,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
};
