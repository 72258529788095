import * as $$ from "jquery-selector-cache";
import {getUrlVar} from "orxapi.tools.url";
import {urlParametersConfig, urlRefineParametersConfig} from "../../_core/scripts/specific/config/form";

/**
 * Initialize the module
 */
export function initGooglePub() {
    // Exit when component don"t exist
    if (!$$("#adcontainer1").length || !$$("#adcontainer2").length) {
        return;
    }
    const _googCsa = (window as any)._googCsa;

    const pageNumber = 1;
    const destination = getDestinationForGoogleAds();
    const pageOptions = {
        "pubId": "pub-5601224529449842",
        "query": "sejour, vacances" + destination,
        "channel": "lastminutefr-holidays",
        "hl": "fr",
        "linkTarget": "_blank",
        "adPage": pageNumber,
        "location": false,
        "styleId": "7287342044",
        "plusOnes": false,
        "sellerRatings": false,
        "siteLink": true,
        "rolloverLinkUnderline": true,
        "titleBold": true,
        "personalizedAds": true
    };

    const adblock1 = {
        "container": "adcontainer1",
        "maxTop": 4,
        "width": "100%",
        "fontSizeTitle": 12,
        "colorTitleLink": "#EC008C",
        "colorDomainLink": "#FF7E00",
        "detailedAttribution": false
    };
    const adblock2 = {
        "container": "adcontainer2",
        "width": "100%",
        "number": 5,
        "fontSizeTitle": 12,
        "colorTitleLink": "#EC008C",
        "colorDomainLink": "#FF7E00",
        "longerHeadlines": true,
        "detailedAttribution": false
    };
    (window as any)._googCsa("ads", pageOptions, adblock1, adblock2);
}

/**
 * getDestinationForGoogleAds
 * @return {String} cities and countries
 */
export function getDestinationForGoogleAds() {
    let result = getCities() + getCountries();
    if (result.startsWith(", ")) {
        result = result.substring(2);
    }
    return result;
}

/**
 * getCities
 * @return {String} result
 */
function getCities() {
    let result = "";
    let cities = getUrlVar()[urlRefineParametersConfig.destination];
    if (!cities || cities === "") {
        cities = getUrlVar()[urlParametersConfig.destination];
    }
    if (cities && cities !== "") {

        result = getRequestedDestinations(cities, 1);
    }
    return result;
}

/**
 * getCountries
 * @return {String} result
 */
function getCountries() {
    let result = "";
    const countries = getUrlVar()[urlParametersConfig.destination];
    if (countries && countries !== "") {
        result = getRequestedDestinations(countries, 0);
    }
    return result;
}

/**
 * getRequestedDestinations
 * @param {String} destinations
 * @param {Number} level
 * @return {String} result
 */

function getRequestedDestinations(destinations: string, level: number) {
    let result = "";
    const destinationsArray = destinations.split(",");

    destinationsArray.forEach((destination: string) => {
        const destinationCodes = destination.split(".");
        if (destinationCodes.length >= level + 1) {
            if (destinationCodes[level]) {
                result += ", " + destinationCodes[level];
            }
        }
    });
    return result;
}
